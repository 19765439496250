<template>
  <div class="filters-fields">
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.active"
        field-type="select"
        :options="ACTIVE_CHOICES"
        placeholder="All projects..."
        label="Active"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.country"
        field-type="multiselect"
        :options="COUNTRY_CHOICES"
        reverse-field="code"
        label="Country"
        placeholder="Select country"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.cause"
        field-type="multiselect"
        placeholder="Select a cause..."
        label="Causes"
        :options="CAUSE_CHOICES"
        reverse-field="id"
        :validable="false"
        display-id
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.featured"
        field-type="checkbox"
        label="Featured"
        placeholder="Featured"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.text"
        field-type="string"
        label="Search"
        placeholder="Search"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.artist"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.artist"
        label="Artist"
        placeholder="Choose an artist"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.tag"
        field-type="multiselect"
        placeholder="Select tags..."
        :data-source="$TapAdmin.tag"
        reverse-field="id"
        label="Tags"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <!-- TODO : DISABLED UNTIL WORKING -->
    <!-- <BaseInputContainer
      v-model="filterValues.region"
      field-type="select"
      label="Region"
      placeholder="Select region"
      :options="regionChoices"
      :validable="false"
      v-on="$listeners"
    />     -->
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'ProjectFilters',
  mixins: [modelFilters],
  data() {
    return {
      model: 'project',
      ACTIVE_CHOICES: [
        {value: null, display_name: 'All'},
        {value: false, display_name: 'Inactive'},
        {value: true, display_name: 'Active'}
      ]
    }
  }
}
</script>

<style src="../../assets/css/filters.css" scoped></style>
