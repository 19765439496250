<template>
  <div 
    :class="[
      'form',
      {'dirty-form': dirty},
    ]"
  >
    <header>
      <template v-if="itemRaw.id">
        <h2>Article</h2>
        <h1>{{ itemRaw.name }}</h1>
        <!-- ADMIN-TAGS -->
        <slot name="admin-tags" />   
      </template>
      <template v-else>
        <h2>Create new</h2>
        <h1>Article</h1>
      </template>
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Article's data</h2>
      <BaseInputContainer
        v-model="item.title"
        :errors="errors.title"
        field-type="string"
        label="Title"
        :required="availableFields.title.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.content"
        :errors="errors.content"
        field-type="rte"
        label="Content"
        :required="availableFields.content.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.category"
        :errors="errors.category"
        field-type="select"
        placeholder="Select category..."
        label="Category"
        reverse-field="id"
        :data-source="$TapAdmin.articlecategory"
        :required="availableFields.category.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- date -->
      <BaseInputContainer
        v-model="item.date"
        label="Date"
        field-type="date"
        :required="availableFields.date.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.tag_set"
        :errors="errors.tag_set"
        field-type="multiselect"
        placeholder="Select tags..."
        label="Tags"
        reverse-field="id"
        :data-source="$TapAdmin.tag"
        :required="availableFields.tag_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <!-- MEDIA_SET -->
    <section>
      <SetEditor
        :item="item.media_set"
        :errors="errors.media_set"
        label="Media for this article"
        set-model="media_set"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      />
    </section>
    <section>
      <h2> Related Content</h2>
      <BaseInputContainer
        v-model="item.artist_set"
        :errors="errors.artist_set"
        field-type="multiselect"
        placeholder="Select an artist..."
        label="Related artist"
        reverse-field="id"
        :required="availableFields.artist_set.required"
        :data-source="$TapAdmin.artist"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.artwork_set"
        :errors="errors.artwork_set"
        field-type="multiselect"
        placeholder="Select an artwork..."
        label="Related artwork"
        reverse-field="id"
        :required="availableFields.artwork_set.required"
        :data-source="$TapAdmin.artwork"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.cause_set"
        :errors="errors.cause_set"
        field-type="multiselect"
        placeholder="Select a cause..."
        label="Related causes"
        reverse-field="id"
        :options="CAUSE_CHOICES"
        :required="availableFields.cause_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.related_article_set"
        :errors="errors.related_article_set"
        field-type="multiselect"
        placeholder="Select an article..."
        label="Related articles"
        reverse-field="id"
        :required="availableFields.related_article_set.required"
        :data-source="$TapAdmin.article"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.event_set"
        :errors="errors.event_set"
        field-type="multiselect"
        placeholder="Select an event..."
        label="Related events"
        reverse-field="id"
        :required="availableFields.event_set.required"
        :data-source="$TapAdmin.event"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.gallery_set"
        :errors="errors.gallery_set"
        field-type="multiselect"
        placeholder="Select a gallery..."
        label="Related gallery"
        reverse-field="id"
        :required="availableFields.gallery_set.required"
        :data-source="$TapAdmin.gallery"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.institution_set"
        :errors="errors.institution_set"
        field-type="multiselect"
        placeholder="Select an institution..."
        label="Related institutions"
        reverse-field="id"
        :options="CAUSE_CHOICES"
        :required="availableFields.institution_set.required"
        :data-source="$TapAdmin.institution"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.project_set"
        :errors="errors.project_set"
        field-type="multiselect"
        placeholder="Select a project..."
        label="Related projects"
        reverse-field="id"
        :required="availableFields.project_set.required"
        :data-source="$TapAdmin.project"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
  </div>
</template>

<script>
// Mixin
import {modelForms} from '@/mixins/modelForms.js'
// Components
import SetEditor from '@/components/SetEditor'

export default {
  name: "ArticleForm",
  components: {
    SetEditor,
  },
  mixins: [modelForms],
  computed: {
    
  },
  methods: {

  }
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>

</style>
