<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Institution</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Institution</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Institution's informations</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Institution's name"
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="string"
        label="Institution's description"
        :required="availableFields.description.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.website"
        :errors="errors.website"
        field-type="string"
        label="Institution's website"
        :required="availableFields.website.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Place</h2>
      <AddressForm
        :item="item.address"
        :errors="errors.address"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
    <!-- TODO: display artist_set -->
    <!-- TODO: ADD field TAG_SET -->
    <section>
      <h2>Hosted events</h2>
      <BaseInputContainer
        v-model="item.event_set"
        :errors="errors.event_set"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.event"
        label="related events"
        placeholder="Choose an event..."
        :required="availableFields.event_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Media set</h2>
      <SetEditor
        :item="item.media_set"
        :errors="errors.media_set"
        label="Event views"
        set-model="media_set"
        @update="onFieldUpdate"
        @input="onFieldInput"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      >
        <template slot="controls">
          <slot name="controls" />
        </template>
      </SetEditor>
    </section>
  </div>
</template>

<script>
// Mixin
import { modelForms } from '@/mixins/modelForms.js'
// Components
import AddressForm from '@/components/AddressForm'
import SetEditor from '@/components/SetEditor'

export default {
  name: 'InstitutionForm',
  components: {
    SetEditor,
    AddressForm,
  },
  mixins: [modelForms],
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
</style>