<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <!-- TODO: organize the form well -->
    <!-- TODO : check help texts, labels -->
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>NGO</h2>
          <h1>{{ itemRaw.name }}</h1>
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>NGO</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <section
      v-if="!$parent.creation"
      class="details"
    />
    <section>
      <h2>General description</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="NGO's name"
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <AddressForm
        :item="item.address"
        :errors="errors.address"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Activity</h2>
      <BaseInputContainer
        v-model="item.country_set"
        :errors="errors.country_set"
        field-type="multiselect"
        placeholder="Select a county..."
        label="Country involving NGO"
        :options="COUNTRY_CHOICES"
        :required="availableFields.country_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.project_set"
        :errors="errors.project_set"
        field-type="multiselect"
        placeholder="Select a project..."
        label="Projects involving NGO"
        reverse-field="id"
        :required="availableFields.project_set.required"
        :data-source="$TapAdmin.project"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
  </div>
</template>

<script>
// Forms mixin
import { modelForms } from '@/mixins/modelForms.js'
import AddressForm from '@/components/AddressForm'

export default {
  name: 'OrganisationForm',
  components: {
    AddressForm,
  },
  mixins: [modelForms],
  props: {},
  methods: {},
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
