<template>
  <div class="filters-fields">
    <BaseInputContainer
      v-model="filterValues.text"
      field-type="string"
      label="Search"
      placeholder="Search"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.country"
      field-type="select"
      :options="COUNTRY_CHOICES"
      reverse-field="code"
      label="Country"
      placeholder="Select country"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.artist"
      field-type="select"
      reverse-field="id"
      :data-source="$TapAdmin.artist"
      label="Artist"
      placeholder="Choose an artist"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.gallery"
      field-type="multiselect"
      reverse-field="id"
      :data-source="$TapAdmin.gallery"
      label="Gallery"
      placeholder="Choose a gallery"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.institution"
      field-type="select"
      reverse-field="id"
      :data-source="$TapAdmin.institution"
      label="Institution"
      placeholder="Choose an institution"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.causes"
      field-type="multiselect"
      placeholder="Select a cause..."
      label="Causes"
      :options="CAUSE_CHOICES"
      :validable="false"
      display-id
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.date"
      field-type="select"
      :options="DATE_FILTER_CHOICES"
      label="Date"
      placeholder="Select date"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.type"
      field-type="select"
      :options="typeChoices"
      label="Type"
      placeholder="Select type"
      :validable="false"
      v-on="$listeners"
    />
  </div>
</template>

<script>

import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'EventFilters',
  mixins: [modelFilters],
  data() {
    return {
      model: 'event',
    }
  },
};

</script>

<style src="../../assets/css/filters.css" scoped></style>
