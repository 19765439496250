<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Gallery</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Gallery</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Identification</h2>
      <section v-if="itemRaw.id">
        <BaseButton
          label="Send activation email"
          active
          @click="onSendActivationEmailClick"
        />
      </section>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Gallery's name"
        help-text="Provide a name for this gallery."
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.website"
        :errors="errors.website"
        field-type="string"
        label="Gallery's website"
        help-text="Gallery's main website URL (https://example.com)"
        :required="availableFields.website.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.creationyear"
        :errors="errors.creationyear"
        field-type="integer"
        label="Creation year"
        help-text="Gallery's creation year"
        :required="availableFields.creationyear.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Address</h2>
      <AddressForm
        :item="item.address"
        :errors="errors"
        @update="onFieldUpdate"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Public informations</h2>
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="rte"
        label="Description"
        :required="availableFields.description.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.portrait"
        label="Portrait"
        :errors="errors.portrait"
        :disabled-resize="false"
        help-text="You can add PNG & JPG files. 500px minimum. 20MB maximum."
        ratio-type="portrait"
        field-type="image"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.copyright"
        field-type="rte"
        label="Copyright"
        help-text="Copyright/credits for portrait."
        :styles="['small-rte']"
        simple-mode
        :errors="errors ? errors.copyright : null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      />

      <SocialNetworkForm
        :item="item.social_network"
        :errors="errors.social_network"
        v-on="$listeners"
      />
      <section>
        <BaseInputContainer
          v-model="item.tag_set"
          :errors="errors.tag_set"
          field-type="multiselect"
          placeholder="Select tags..."
          label="Tags"
          reverse-field="id"
          :data-source="$TapAdmin.tag"
          :required="availableFields.tag_set.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.pledge_video"
          field-type="url"
          label="Pledge video"
          :errors="errors.pledge_video"
          :required="availableFields.pledge_video.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <BaseInputContainer
        v-model="item.pledge_text"
        :errors="errors.pledge_text"
        field-type="rte"
        placeholder="Enter a pledge text..."
        label="Pledge Text"
        :required="availableFields.pledge_text.required"
        :max-length="availableFields.pledge_text.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Visibility</h2>
      <VisibilityForm
        :item="item.visibility"
        :errors="errors"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
    <SetEditor
      :item="item.space_set"
      :errors="errors.space_set"
      label="Gallery's exhibition spaces"
      set-model="space_set"
      v-on="$listeners"
      @dirtysubform="onDirtySubform"
    />
    <slot name="controls" />
    <section>
      <h2>Legal information</h2>
      <LegalInformation
        :item="item.admin"
        :errors="errors"
        v-on="$listeners"
      />
      <SetEditor
        :item="item.gallery_to_artist"
        :errors="errors.gallery_to_artist"
        label="Tax receipt settings"
        set-model="gallery_to_artist"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      />
      <slot name="controls" />
    </section>
  </div>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'
import AddressForm from '@/components/AddressForm'
import LegalInformation from '@/components/gallery/LegalInformation'
import SetEditor from '@/components/SetEditor'
import SocialNetworkForm from '@/components/SocialNetworkForm'
import VisibilityForm from '@/components/VisibilityForm'

export default {
  name: 'GalleryForm',
  components: {
    AddressForm,
    LegalInformation,
    SetEditor,
    SocialNetworkForm,
    VisibilityForm,
  },
  mixins: [modelForms],
  methods: {
    onSendActivationEmailClick: function () {
      this.$store.dispatch('ui/openModal', {
        title: 'Are you sure ?',
        message: `Do you really want to send an activation email to the gallery ${this.itemRaw.name} (${this.itemRaw.admin.email}) ?`,
        theme: 'warning',
        successCallback: () => {
          this.$TapAdmin.seller
            .sendActivationEmail(this.itemRaw.admin.pk)
            .then((response) => {
              console.debug('GALLERY: seller activation response', response)
            })
        },
        cancelCallback: () => {},
      })
    },
  },
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
