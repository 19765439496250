import Vue from 'vue'
import Vuex from 'vuex'

/* STORE MODULES */
import address from './modules/address'
import article from './modules/article'
import artist from './modules/artist'
import artwork from './modules/artwork'
import cause from './modules/cause'
import country from './modules/country'
import currency from './modules/currency'
import event from './modules/event'
import gallery from './modules/gallery'
import institution from './modules/institution'
import organisation from './modules/organisation'
import project from './modules/project'
import tag from './modules/tag'
import transaction from './modules/transaction'

/* Image for home page grids */
import fieldpicture from './modules/fieldpicture'

/* special store modules */
import choices from './modules/choices'
import user from './modules/user'
import ui from './modules/ui'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    address,
    article,
    article,
    artist,
    artwork,
    cause,
    country,
    currency,
    event,
    fieldpicture,
    gallery,
    institution,
    organisation,
    project,
    tag,
    transaction,

    choices,
    user,
    ui,
  },
  strict: debug,
})
