var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-view"},[_c('header',[_c('h1',[_vm._v(_vm._s(_vm.state.modelDisplayNames[1]))])]),_c('section',{attrs:{"id":"filters"}},[_c('FiltersModule',{attrs:{"model-name":_vm.model}})],1),_c('section',{attrs:{"id":"results"}},[_c('section',{attrs:{"id":"results-header"}},[_c('div',{attrs:{"id":"results-infos"}},[_vm._v(" "+_vm._s(_vm.resultsInfos)+" ")]),(_vm.canCreateModelInstance)?[_c('BaseButton',{attrs:{"label":'+ Add ' + _vm.model,"styles":['CTA']},on:{"click":function($event){return _vm.$router.push({ name: _vm.model + '-creation' })}}})]:_vm._e(),_c('Pagination',{attrs:{"model-name":_vm.model}})],2),(!_vm.resultsReady && !_vm.results.length)?_c('BaseLoader'):_c('table',{key:'list' + _vm.model,attrs:{"id":"results-list"}},[_c('thead',[_c('tr',[_c('th',[_c('BaseCheckbox',{key:'ckx' + _vm.model,attrs:{"id":'ckx' + _vm.model,"value":_vm.allSelected},nativeOn:{"click":function($event){return _vm.toggleAllSelected.apply(null, arguments)}}})],1),_vm._l((_vm.columns),function(column,columnKey){return _c('th',{key:columnKey,class:{
              'sorting-column': _vm.orderingField == columnKey,
            }},[_c('div',{staticClass:"th-content"},[_c('div',{staticClass:"column-label",on:{"click":function($event){column.sortable ? _vm.sortList(columnKey, !_vm.orderingDesc) : null}}},[_vm._v(" "+_vm._s(column.label)+" ")]),(column.sortable)?[_c('div',{staticClass:"sort-buttons"},[_c('div',{class:[
                      'sort-button',
                      { 'this-way': _vm.orderingField == columnKey && !_vm.orderingDesc } ]},[_c('img',{attrs:{"src":require("../base/assets/img/svgicons/chevron-up.svg")},on:{"click":function($event){return _vm.sortList(columnKey, false)}}})]),_c('div',{class:[
                      'sort-button',
                      {
                        'this-way':
                          _vm.orderingField == columnKey && _vm.orderingDesc,
                      } ]},[_c('img',{attrs:{"src":require("../base/assets/img/svgicons/chevron-down.svg")},on:{"click":function($event){return _vm.sortList(columnKey, true)}}})])])]:_vm._e()],2)])}),_c('th')],2)]),(_vm.rowComponent)?_c('tbody',{class:[{ refreshing: !_vm.resultsReady }]},[_vm._l((_vm.results),function(result,rIdx){return [_c(_vm.rowComponent,{key:'r' + rIdx,ref:"rowComponent",refInFor:true,tag:"RowElement",class:{ 'selected-row': _vm.isSelected(result) },attrs:{"object":result,"model":_vm.model}},[_c('template',{slot:"listSelectionControls"},[_c('td',[_c('BaseCheckbox',{attrs:{"value":_vm.isSelected(result)},nativeOn:{"click":function($event){return _vm.toggleSelected(result)}}})],1)]),_c('template',{slot:"defaultAvailableActions"},[_c('td',{staticClass:"default-actions"},[_c('div',{staticClass:"actions-container"},[_c('router-link',{attrs:{"title":(_vm.model + " details"),"to":{ name: (_vm.model + "-view"), params: { id: result.id } }}},[_c('BaseButton',{attrs:{"styles":['list-icon'],"icon":"external-link"}})],1),_c('BaseButton',{attrs:{"styles":['list-icon'],"icon":"delete","title":("Delete " + _vm.model)},on:{"click":function($event){return _vm.deleteObject(result)}}})],1)])])],2)]})],2):_vm._e()]),_c('section',{attrs:{"id":"results-footer"}},[_c('pagination',{attrs:{"model-name":_vm.model}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }