import { render, staticRenderFns } from "./SetEditor.vue?vue&type=template&id=143b9f84&scoped=true&"
import script from "./SetEditor.vue?vue&type=script&lang=js&"
export * from "./SetEditor.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/forms.css?vue&type=style&index=0&id=143b9f84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143b9f84",
  null
  
)

export default component.exports