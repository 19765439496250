<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <img
          v-if="thumbnail"
          :src="thumbnail"
          class="thumb"
        >
      </div>
    </td>
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.artist_set.length }}
    </td>
    <td>
      {{ object.gallery_set.length }}
    </td>
    <td>
      <p
        v-for="(institution, index) in object.institution_set.slice(0, 2)"
        :key="index"
      >
        <router-link
          :to="{ name: 'institution-view', params: { id: institution.id } }"
        >
          {{ institution.name }}
        </router-link>
      </p>
      <p v-if="object.institution_set.length > 2">
        ...
      </p>
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'

export default {
  name: 'EventRow',
  mixins: [modelRowUtil],
  computed: {
    thumbnail: function () {
      if (this.object.media_set != null && this.object.media_set.length > 0) {
        if (this.object.media_set[0].picture) {
          return this.object.media_set[0].picture.thumbnail
        }
      }
      return null
    },
  },
}
</script>

<style src="@/assets/css/listrows.css" scoped></style>