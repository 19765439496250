<template>
  <tr>
    <!-- see ListView for slot declaration -->
    <slot name="listSelectionControls" />
    <!-- PORTRAIT -->
    <td>
      <div class="thumb-container">
        <img
          v-if="object.portrait"
          :src="object.portrait.thumbnail"
          class="thumb"
        >
      </div>
    </td>
    <!-- NAME -->
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.metadata.featured }}
    </td>
    <!-- GALLERY_SET -->
    <td>
      <p
        v-for="(gallery, index) in object.gallery_set"
        :key="index"
      >
        <template v-if="object.gallery_set.length > 1">
          &middot;
        </template>
        <router-link
          :to="{name: 'gallery-view', params: { id: gallery.id }}"
          title="Gallery details"
        >
          {{ gallery.name }}
        </router-link>
      </p>
    </td>
    <!-- ARTWORK_SET -->
    <td>
      <p
        v-for="(artwork, index) in object.artwork_set.slice(0, 2)"
        :key="index"
      >
        <router-link
          :to="{name: 'artwork-view', params: { id: artwork.id }}"
          title="Artwork details"
        >
          &middot; <span class="title">{{ artwork.title | truncate(20) }}</span>, {{ artwork.year }}
        </router-link>
      </p>
      <p v-if="object.artwork_set.length > 2">
        {{ object.artwork_set.length - 2 }} more...
      </p>
    </td>
    <!-- CAUSE_SET -->
    <td>
      <span
        v-for="(cause, index) in object.cause_set"
        :key="index"
      >
        &middot;
        <router-link
          :title="`${cause.name} details`"
          :to="{name: 'cause-view', params: { id: cause.id }}"
        >
          {{ cause.id }}
        </router-link>
      </span>
    </td>
    <!-- STATUS -->
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <!-- see ListView for slot declaration -->
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'ArtistRow',
  mixins: [modelRowUtil],
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>
