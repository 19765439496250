/*
  This store is NOT based on basestore.
  It is a special store connected to a dedicated route on the API
  to retrieve all available choices.
*/
import Vue from 'vue'

const state = {
  tapApi: {},
  choicesReady: false,
}

const mutations = {
  setTapApi: function(state, value) {
    state.tapApi = value
  },
  setChoices: function(state, choicesArray) {
    /* here we create dynamic state variables based upon 
    available choices returned by API -- they are all CAPS_CHOICES */
    // console.log(choicesArray)
    const keys = choicesArray.forEach((choicesDict) => {
      const choice = Object.entries(choicesDict)[0]
      const key = choice[0]
      const values = choice[1]
      const formattedChoices = [...values.results]
      /* ⚠ state variable creation here */
      Vue.set(state, key, formattedChoices)
      // if (values.reverse_field || values.display_field){
      //   Vue.set(state.specialChoices, key, {
      //     displayField: values.display_field,
      //     reverseField: values.reverse_field
      //   })
      // }
    })
  },
  setSpecialChoices: function(state, details) {
    const formattedChoices = makeChoicesFromResults(
      details.choices,
      details.reverseField,
      details.displayField,
      details.indexed
    )
    // Vue.set(state.specialChoices, details.choicesName, {
    //   displayField: details.displayField,
    //   reverseField: details.reverseField
    // })
    Vue.set(state, details.choicesName, formattedChoices)
  },
  setChoicesReady: function(state, ready) {
    state.choicesReady = ready
  },
}

const actions = {
  initStore: function({ commit, state }, tapApi) {
    commit('setChoicesReady', false)
    commit('setTapApi', tapApi)
    Promise.all([
      /* CHOICES standard route */
      state.tapApi.choices().then((response) => {
        const manipulatedData = response.data.map((o) => {
          /* SPECIAL for projects: we do not want user to set 'imported'
          and then being unable to change it anymore (API behaviour) */
          if (Object.keys(o).includes('PROJECT_SOURCE_CHOICES')) {
            const no = {
              PROJECT_SOURCE_CHOICES: {
                results: o.PROJECT_SOURCE_CHOICES.results.filter(
                  (r) => r.value != 'IMP'
                ),
              },
            }
            return no
          } else {
            return o
          }
        })
        commit('setChoices', manipulatedData)
      }),
      /* COUNTRIES as choices */
      state.tapApi.country.search({ limit: 300 }).then((response) => {
        const choicesFromApi = response.data.results
        commit('setSpecialChoices', {
          choicesName: 'COUNTRY_CHOICES',
          choices: choicesFromApi,
          displayField: 'name',
          reverseField: 'code',
        })
      }),
      /* CAUSES as choices */
      state.tapApi.cause.search({ limit: 300 }).then((response) => {
        const choicesFromApi = response.data.results
        commit('setSpecialChoices', {
          choicesName: 'CAUSE_CHOICES',
          choices: choicesFromApi,
          displayField: 'name',
          reverseField: 'id',
        })
      }),
      /* CURRENCIES as choices */
      state.tapApi.currency.search({ limit: 1000 }).then((response) => {
        const choicesFromApi = response.data.results
        commit('setSpecialChoices', {
          choicesName: 'CURRENCY_CHOICES',
          choices: choicesFromApi,
          displayField: 'name',
          reverseField: 'code',
        })
      }),
      /* TRANSACTION STATUS as choices */
      state.tapApi.transactionStatus.getTransactionStatusChoices().then((response) => {
        const choicesFromApi = response.data
        commit('setSpecialChoices', {
          choicesName: 'TRANSACTION_STATUS_CHOICES',
          choices: choicesFromApi,
          displayField: 'name',
          reverseField: 'id',
        })
      }),
      /* SHIPMENT STATUS as choices */
      state.tapApi.transactionStatus.getShipmentStatusChoices().then((response) => {
        const choicesFromApi = response.data
        commit('setSpecialChoices', {
          choicesName: 'SHIPMENT_STATUS_CHOICES',
          choices: choicesFromApi,
          displayField: 'name',
          reverseField: 'id',
        })
      })
    ]).then(() => {
      commit('setChoicesReady', true)
    })
  },
}
const getters = {
  getChoiceDisplay: (state) => ([field_name, field_value]) => {
    if (!state.choicesReady) return '(LOADING)'
    const key = field_name.toUpperCase() + '_CHOICES'
    const key2 = field_name.toUpperCase() + '_FILTER_CHOICES'
    // console.debug('trying choicedisplay for ', field_name, field_value, key, key2)
    if (typeof field_value == 'object')
      field_value = Object.values(field_value)[0]
    let choice = null
    if (field_name.includes('country')) {
      choice = state.COUNTRY_CHOICES.find((c) => c.value == field_value)
    }
    if (state[key]) {
      choice = state[key].find((c) => c.value == field_value)
    }
    if (state[key2]) {
      choice = state[key2].find((c) => c.value == field_value)
    }
    return choice
  },
}

const makeChoicesFromResults = function(results, valueKey, nameKey) {
  return results.map((c) => {
    return {
      value: c[valueKey],
      display_name: c[nameKey],
    }
  })
}

// const makeChoicesFromResults = function(
//   results,
//   valueKey,
//   nameKey,
//   indexed = false
// ) {
//   return results.map((c) => {
//     return {
//       value: c[valueKey],
//       display_name: (indexed == true ? c['id'] + '. ' : '') + c[nameKey],
//     }
//   })
// }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
