<template>
  <div class="filters-fields">
    <BaseInputContainer
      v-model="filterValues.text"
      field-type="string"
      label="Search"
      placeholder="Search"
      :validable="false"
      v-on="$listeners"
    />
  </div>
</template>

<script>

import {modelFilters} from '@/mixins/modelFilters.js'

export default {
  name: 'CauseFilters',
  mixins: [modelFilters],
  data: function () {
    return {
      model: 'cause',
    }
  },
};

</script>

<style src="../../assets/css/filters.css" scoped></style>