<template>
  <div class="list-view">
    <!-- PAGE HEADER -->
    <header>
      <h1>{{ state.modelDisplayNames[1] }}</h1>
    </header>
    <!-- FILTERS AND SEARCH OPTIONS -->
    <section id="filters">
      <FiltersModule :model-name="model" />
    </section>
    <!-- RESULTS AND RESULTS INFOS -->
    <section id="results">
      <!-- INFOS AND PAGINATION -->
      <section id="results-header">
        <div id="results-infos">
          {{ resultsInfos }}
        </div>
        <template v-if="canCreateModelInstance">
          <BaseButton
            :label="'+ Add ' + model"
            :styles="['CTA']"
            @click="$router.push({ name: model + '-creation' })"
          />
        </template>
        <Pagination :model-name="model" />
      </section>
      <!-- A LOADER IS SHOWN WHILE FETCHING DATA -->
      <BaseLoader v-if="!resultsReady && !results.length" />
      <!-- --- --- T H E    T A B L E   E L E M E N T --- --- -->
      <table
        v-else
        id="results-list"
        :key="'list' + model"
      >
        <thead>
          <tr>
            <!-- SELECT ALL  -->
            <th>
              <BaseCheckbox
                :id="'ckx' + model"
                :key="'ckx' + model"
                :value="allSelected"
                @click.native="toggleAllSelected"
              />
            </th>
            <!-- ALL THE COLUMNS -->
            <th
              v-for="(column, columnKey) in columns"
              :key="columnKey"
              :class="{
                'sorting-column': orderingField == columnKey,
              }"
            >
              <!-- ONE COLUMN -->
              <div class="th-content">
                <!-- COLUMN LABEL TEXT -->
                <div
                  class="column-label"
                  @click="column.sortable ? sortList(columnKey, !orderingDesc) : null"
                >
                  {{ column.label }}
                </div>
                <!-- COLUMN SORTING ICONS -->
                <template v-if="column.sortable">
                  <div class="sort-buttons">
                    <div
                      :class="[
                        'sort-button',
                        { 'this-way': orderingField == columnKey && !orderingDesc },
                      ]"
                    >
                      <img
                        src="../base/assets/img/svgicons/chevron-up.svg"
                        @click="sortList(columnKey, false)"
                      >
                    </div>
                    <div
                      :class="[
                        'sort-button',
                        {
                          'this-way':
                            orderingField == columnKey && orderingDesc,
                        },
                      ]"
                    >
                      <img
                        src="../base/assets/img/svgicons/chevron-down.svg"
                        @click="sortList(columnKey, true)"
                      >
                    </div>
                  </div>
                </template>
              </div>
            </th>
            <!-- last column is for default availableActions-->
            <th />
          </tr>
        </thead>
        <!-- 
            -------------- TABLE BODY -------------------------
            contains all rows of the table 
        -->
        <tbody
          v-if="rowComponent"
          :class="[{ refreshing: !resultsReady }]"
        >
          <template v-for="(result, rIdx) in results">
            <!-- rowelement is mapped in computed -->
            <RowElement
              :is="rowComponent"
              :key="'r' + rIdx"
              ref="rowComponent"
              :class="{ 'selected-row': isSelected(result) }"
              :object="result"
              :model="model"
            >
              <!-- 
                Adding <slot name="listSelectionControls"
                to row elements provides checkbox selection capability 
              -->
              <template slot="listSelectionControls">
                <td>
                  <BaseCheckbox
                    :value="isSelected(result)"
                    @click.native="toggleSelected(result)"
                  />
                </td>
              </template>
              <!-- 
                Adding <slot name="defaultAvailableActions" 
                provides default action buttons for list elements.
              -->
              <template slot="defaultAvailableActions">
                <td class="default-actions">
                  <div class="actions-container">
                    <router-link
                      :title="`${model} details`"
                      :to="{ name: `${model}-view`, params: { id: result.id } }"
                    >
                      <BaseButton
                        :styles="['list-icon']"
                        icon="external-link"
                      />
                    </router-link>
                    <!-- <BaseButton -->
                    <!--   :styles="['list-icon']" -->
                    <!--   icon="message-square" -->
                    <!--   @click="exportObject(result)" -->
                    <!-- /> -->
                    <!-- <BaseButton -->
                    <!--   :styles="['list-icon']" -->
                    <!--   icon="copy" -->
                    <!--   @click="duplicateObject(result)" -->
                    <!-- /> -->
                    <BaseButton
                      :styles="['list-icon']"
                      icon="delete"
                      :title="`Delete ${model}`"
                      @click="deleteObject(result)"
                    />
                    <!-- <BaseButton -->
                    <!--   :styles="['list-icon']" -->
                    <!--   icon="archive" -->
                    <!--   @click="archiveObject(result)" -->
                    <!-- /> -->
                  </div>
                </td>
              </template>
            </RowElement>
          </template>
        </tbody>
      </table>
      <!-- ------ THE LIST FOOTER -------  -->
      <!-- TODO : ADD buttons for BULK what to do ! -->
      <section id="results-footer">
        <pagination :model-name="model" />
      </section>
    </section>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { columns } from '@/config/listViewColumnMap'

import FiltersModule from '@/components/FiltersModule'
import Pagination from '@/components/Pagination.vue'

import ArticleRow from '@/components/article/ArticleRow'
import ArtistRow from '@/components/artist/ArtistRow'
import ArtworkRow from '@/components/artwork/ArtworkRow'
import CauseRow from '@/components/cause/CauseRow'
import EventRow from '@/components/event/EventRow'
import FieldPictureRow from '@/components/fieldpicture/FieldPictureRow'
import GalleryRow from '@/components/gallery/GalleryRow'
import InstitutionRow from '@/components/institution/InstitutionRow'
import OrganisationRow from '@/components/organisation/OrganisationRow'
import ProjectRow from '@/components/project/ProjectRow'
import TagRow from '@/components/tag/TagRow'
import TransactionRow from '@/components/transaction/TransactionRow'

export default {
  name: 'ListView',
  components: {
    // /* Rows & filters */
    ArticleRow,
    ArtistRow,
    ArtworkRow,
    CauseRow,
    EventRow,
    FieldPictureRow,
    FiltersModule,
    GalleryRow,
    InstitutionRow,
    OrganisationRow,
    ProjectRow,
    TagRow,
    TransactionRow,
    /* components */
    Pagination,
  },
  props: {
    model: {
      type: String,
      required: true,
      default: null,
    },
  },
  data: function () {
    return {
      filterValues: {},
    }
  },
  computed: {
    ...mapState({
      state(state) {
        return state[this.model]
      },
    }),
    modelName: function () {
      return this.state.modelName
    },
    results: function () {
      return this.state.searchResults
    },
    resultsReady: function () {
      return this.state.resultsReady
    },
    resultsInfos: function () {
      const n =
        (this.state.searchResponse &&
          this.state.searchResponse.data &&
          this.state.searchResponse.data.count) ||
        0
      const modelPlural = this.state.modelDisplayNames[n > 1 ? 1 : 0]
      return `${n} ${modelPlural
        .slice(0, 1)
        .toLocaleUpperCase()}${modelPlural.slice(1)}`
      // return this.$options.filters.formatNumber(n, this.modelName)
    },
    allSelected: function () {
      return this.state.allSelected
    },
    orderingField: function () {
      return this.state.orderingField.replace('-', '')
    },
    orderingDesc: function () {
      return this.state.orderingDesc
    },
    selectedResults: function () {
      return this.state.selectedResults
    },
    columns: function () {
      return columns[this.model]
    },
    rowComponent: function () {
      const rowMap = {
        // default types
        article: 'ArticleRow',
        artist: 'ArtistRow',
        artwork: 'ArtworkRow',
        cause: 'CauseRow',
        event: 'EventRow',
        fieldpicture: 'FieldPictureRow',
        gallery: 'GalleryRow',
        institution: 'InstitutionRow',
        organisation: 'OrganisationRow',
        project: 'ProjectRow',
        tag: 'TagRow',
        transaction: 'TransactionRow',
      }
      if (Object.keys(rowMap).includes(this.model)) {
        return rowMap[this.model]
      } else {
        console.debug('⚠ - No row component for this model.', this.model)
        return null
      }
    },
    canCreateModelInstance: function () {
      const excludeModels = ['cause', 'transaction']
      const canCreate = !excludeModels.includes(this.model)
      return canCreate
    }
  },
  watch: {
    model: function () {
      this.initView()
    },
  },
  mounted: async function () {
    await this.initView()
  },
  methods: {
    initView: async function () {
      if (!this.resultsReady) {
        await this.goSearch()
      }
      this.setListPageTitle()
    },
    sortList: function (column, desc) {
      this.$store.dispatch(this.model + '/setOrdering', { column, desc })
    },
    goSearch: async function () {
      this.$store.commit(this.model + '/updateSearchQuery')
      return this.$store.dispatch(this.model + '/search')
    },
    setListPageTitle: function () {
      this.$nextTick(() => {
        const modelPlural = this.state.modelDisplayNames[1]
        const pageTitle = `${modelPlural
          .slice(0, 1)
          .toLocaleUpperCase()}${modelPlural.slice(1)}`
        this.$store.dispatch('ui/setAppTitle', 'TAP-' + pageTitle)
      })
    },
    /* ----- selection ----- */
    toggleAllSelected: function () {
      this.$store.commit(this.model + '/toggleAll')
    },
    toggleSelected: function (result) {
      this.$store.commit(this.model + '/toggleSelected', result)
    },
    isSelected: function (result) {
      return !!this.selectedResults.find((sr) => sr.id == result.id)
    },
    /* ----- defaultavailableactions ------ */
    /**
     * TODO BELOW
     * - duplication
     * - export
     * needs to be done serverside
     * - archive action is undefined
     */
    duplicateObject: function (item) {
      //TODO: add real action
      console.debug('duplicate object')
      // let clonedObject = { ...item }
      // delete clonedObject.id
      // delete clonedObject.metadata.id
      // this.$TapAdmin[this.model].create(clonedObject).then(() => {
      //   this.goSearch()
      // })
    },
    deleteObject: function (item) {
      this.$store.dispatch('ui/openModal', {
        title: 'Are you sure ?',
        message: `Do you really want to delete ${this.model} ${item.name || item.title || item.id} ?`,
        theme: 'warning',
        successCallback: () => {
          this.$TapAdmin[this.model].destroy(item.id).then(() => {
            this.goSearch()
          })
        },
        cancelCallback: () => {},
      })
    },
    exportObject: function (item) {
      //TODO: add real action
      console.debug('Export object', item)
    },
    archiveObject: function (item) {
      //TODO: add real action
      console.debug('Archive object')
    },
  },
}
</script>

<style scoped>
/* list page header */
header {
  border: var(--default-admin-border);
  padding: 20px;
  margin-bottom: 10px;
}
header h1 {
  font-family: 'Rubik SemiBold';
  font-size: var(--h3);
  text-transform: uppercase;
}
/* --- FILTERS SECTION --- */
/* .filters {} */
/* .filters-row {
  display: flex;
  flex-wrap: wrap;
} */
#results{
  /* width: 100%; */
  /* width: calc(100% - 1px); */
  margin-right: 1px;
  margin-bottom: 100px;
}
/* --- RESULTS HEADER between filters and table */
#results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#results-infos {
  font-family: 'Filson Pro Bold';
  font-size: 1.25rem;
  letter-spacing: var(--h4-ls);
  text-transform: uppercase;
  vertical-align: middle;
}
/* --- THE ACTUAL TABLE WITH RESULTS */
table#results-list {
  width: 100%;
  user-select: none;
  border-collapse: separate;
  border-spacing: 0px 10px;
}
/* --- table header --- */
thead {
  background-color: #dbdbdb;
}
th {
  font-weight: normal;
  height: 2rem;
}
th:first-child {
  padding: 0px 10px; /* for selectall checkbox */
}
th:hover {
  background-color: #7b7b7b80;
}
th.sorting-column {
  background-color: var(--disabled-color);
}
/* --- table ROWs --- */
tr {
  outline: var(--default-admin-border);
  /* border: var(--default-admin-border); */
}
thead tr {
  height: 2rem;
  /* outline: none; */
}
/* --- table CONTENT --- */
tbody {
  font-family: 'Filson Pro Medium';
  font-size: var(--h6);
}
tbody tr{
  /* padding: 10px; */
}
tbody tr:hover {
  background-color: #f1f1f1;
  user-select: none;
}
tbody tr.selected-row {
  background-color: #7b7b7b;
  color: #fff;
}
tbody tr.selected-row .list-icon {
  filter: invert(1);
}

/* ---- Loaders .. for patience .. */
/* TODO: validate list updating loader */
tbody.refreshing {
  /* filter: blur(4px); */
  filter: saturate(0) opacity(0.3);
}
.loader-icon {
  margin: 10rem auto;
}
/* --- COLUMNS HEADER ---- */
.th-content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  text-transform: uppercase;
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  height: 2rem;
}
.th-content .column-label {
  padding: 8px 10px;
  letter-spacing: var(--h4-ls);
  height: 100%;
  box-sizing: border-box;
}
/* COLUMNS HEADER -- SORTING BUTTONS --- */
.sort-buttons {
  align-self: stretch;
  display: flex;
}
.sort-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sort-button:hover {
  filter: invert(1);
  background: #7b7b7be6;
}
.sort-button img {
  width: 1rem;
  cursor: pointer;
  transition: 0.1s linear;
}
.sorting-column .sort-button.this-way {
  background-color: #ffffff80;
}
.sorting-column .column-label{
  color: var(--white);
}
.sorting-column .sort-button.this-way img {
  filter: invert(1);
}
.sorting-column .sort-button:hover {
  background-color: #00000033;
  filter: none;
}
.sorting-column .sort-button:hover img {
  filter: invert(0.5);
}
/* --- ACTIONS BUTTONS --- */
td.default-actions {
  border-left: var(--default-admin-border);
  width: 100px;
  /* width: 3.2rem; */
  box-sizing: border-box;
}
.default-actions .actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-row td.default-actions {
  border-color: #f1f1f1;
}
/* --- PAGINATION COMPONENT external layout --- */
#pagination-component {
  flex-grow: 1;
}
#results-footer {
  display: flex;
  justify-content: flex-end;
}
</style>