<template>
  <div class="navbar">
    <template v-for="(menuLink, lIdx) in menuLinks">
      <router-link
        :key="'menulink' + lIdx"
        :to="menuLink.to"
        :class="{
          'router-link-active':
            menuLink.to.name.split('-')[0] == $route.name.split('-')[0],
        }"
      >
        <div class="menu-element">
          <!-- <div class="element-icon">
            <img :src="require('@/base/assets/img/svgicons/'+menuLink.icon+'.svg')">
          </div> -->
          <div class="element-label">
            {{ menuLink.label }}
          </div>
        </div>
      </router-link>
    </template>

    <!-- <div class="action-buttons"> -->
    <!-- <BaseButton -->
    <!--   label="Profile" -->
    <!--   :disabled="true" -->
    <!--   @click="onProfileButtonClick" -->
    <!-- /> -->
    <!-- <div 
        @click="toggleMenuSize"
      >
        <template v-if="!menuSmall">
          <div class="menu-toggle">
            <img :src="require('@/base/assets/img/svgicons/chevrons-left.svg')">
            <p>Collapse menu</p>
          </div>
        </template>
        <template v-else>
          <div class="menu-toggle">
            <p>Expand menu</p>
            <img :src="require('@/base/assets/img/svgicons/chevrons-right.svg')">
          </div>
        </template>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuAdmin',
  components: {},
  props: {},
  data: function () {
    return {
      menuLinks: [
        { label: 'Dashboard', icon: 'monitor', to: { name: 'dashboard' } },
        { label: 'Artists', icon: 'github', to: { name: 'artist-list' } },
        { label: 'Artworks', icon: 'image', to: { name: 'artwork-list' } },
        { label: 'Galleries', icon: 'instagram', to: { name: 'gallery-list' } },
        { label: 'Causes', icon: 'sunrise', to: { name: 'cause-list' } },
        { label: 'NGO projects', icon: 'target', to: { name: 'project-list' } },
        { label: 'NGO', icon: 'target', to: { name: 'organisation-list' } },
        { label: 'Events', icon: 'target', to: { name: 'event-list' } },
        {
          label: 'Institutions',
          icon: 'target',
          to: { name: 'institution-list' },
        },
        { label: 'Tags', icon: 'target', to: { name: 'tag-list' } },
        { label: 'Transactions', icon: 'target', to: { name: 'transaction-list' } },
        {
          label: 'Grid pictures',
          icon: 'target',
          to: { name: 'fieldpicture-list' },
        },
        { label: 'Articles', icon: 'target', to: { name: 'article-list' } },
        { label: 'Settings', icon: 'target', to: { name: 'public-settings' } },
      ],
    }
  },
  computed: {
    ...mapState('ui', {
      menuSmall: (state) => state.menuSmall,
    }),
  },
  methods: {
    onProfileButtonClick: function () {
      this.$router.push({ name: 'ProfileSettings' })
    },
    toggleMenuSize: function () {
      this.$store.dispatch('ui/toggleMenuSize')
    },
  },
}
</script>

<style scoped>
.navbar {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--ruler-color);
  transition: width 0.1s ease-in-out;
}
.menu-element {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}
.menu-element:hover {
  /* background-color: #00000033; */
  background-color: var(--flat-color);
}
.element-icon {
  display: flex;
  align-items: center;
}
.element-label {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  letter-spacing: var(--h7-ls);
  text-transform: uppercase;
  /* padding-left: 10px; */
}
a {
  text-decoration: none;
  color: #000;
}
.router-link-active .menu-element {
  /* border-right: 5px solid #000; */
  background-color: var(--primary-color);
  color: var(--white);
}
/* .action-buttons {
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
} */
/* .menu-toggle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Filson Pro Bold';
  font-size: 0.7rem;
  letter-spacing: 0.042rem;
  margin-top: 1.65rem;
  cursor: pointer;
} */
/* MENU IS SMALL */
.menu-small .menu-element {
  height: 3rem;
  padding: 10px;
}
.menu-small .element-label {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
}
</style>
