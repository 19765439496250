<template>
  <section v-if="item">
    <AddressForm
      :item="item"
      :errors="errors"
      v-on="$listeners"
    />
    <MediaForm 
      :item="item"
      :errors="errors"
      v-on="$listeners"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'
import AddressForm from '@/components/AddressForm'
import MediaForm from '@/components/MediaForm'

export default {
  name: "SpaceForm",
  components: {
    AddressForm,
    MediaForm
  },
  mixins: [modelForms],
  props: {
  },
  computed: {
    // addressErrors: function(){
    //   if (this.errors && this.errors.address){
    //     return this.errors.address
    //   } else {
    //     return {}
    //   }
    // },
  },
  watch: {

  }
}
</script>

<style src="@/assets/css/forms.css" scoped></style>
<style scoped>

</style>
