/*                                    //   ) )                                
   / __      ___      ___      ___   ((      __  ___  ___      __      ___    
  //   ) ) //   ) ) ((   ) ) //___) )  \\     / /   //   ) ) //  ) ) //___) ) 
 //   / / //   / /   \ \    //           ) ) / /   //   / / //      //        
((___/ / ((___( ( //   ) ) ((____ ((___ / / / /   ((___/ / //      ((____     

    « Jack of all trades, master of none » -- english idiomatic 

    If unfamiliar with vuex please take a look at : https://vuex.vuejs.org/#what-is-a-state-management-pattern

*/

import Vue from 'vue'

const state = {
  modelName: '',
  modelDisplayNames: [],
  /* API mapping is done at app init */
  tapApi: {},
  endpoint: {},
  /* detailed info about available fields for this model */
  availableFields: {},
  /* detailed info about available filter fields for this route */
  availableFilterFields: {},
  /* to inform about loading state of this store module */
  fieldsReady: false,
  resultsReady: false,
  /* search query is an object that stores query parameters */
  searchQuery: {},
  appliedFilters: {},
  filterValues: {},
  orderingField: '',
  orderingDesc: true,
  offset: 0,
  limit: 50,
  /* search results and raw API response */
  searchResults: [],
  searchResponse: {},
  selectedResults: [],
  /* ui */
  showSelectedResults: false,
  allSelected: false,
}

const mutations = {
  /**
   * Endpoint points to an API 'service' method
   * @param {*} value
   */
  setTapApi: function (state, tapApi) {
    state.tapApi = tapApi
    state.endpoint = state.tapApi[state.modelName]
    // state.endpoint = value
  },
  setModelName: function (state, value) {
    state.modelName = value
  },
  /**
   * The specific model's direct fields informations.
   * Contains details about fields type, constraints, etc...
   * @param {*} value
   */
  setAvailableFields: function (state, value) {
    state.availableFields = { ...value }
  },
  /**
   * The specific model's available filters descriptions.
   * Contains details about applicable filters for search query.
   * @param {*} value
   */
  setAvailableFilterFields: function (state, value) {
    state.availableFilterFields = { ...value }
  },
  /**
   * Until all available fields informations are loaded
   * this should stay false.
   * @param {*} value
   */
  setFieldsReady: function (state, value) {
    state.fieldsReady = value
  },
  setOffset: function (state, value) {
    state.offset = value
  },
  setLimit: function (state, value) {
    state.limit = value
  },
  updateAppliedFilters: function (state, value) {
    state.appliedFilters = { ...value }
  },
  resetFilters: function (state) {
    state.appliedFilters = []
  },
  /**
   * SearchQuery is an URLSearchParams JS object.
   * It maps to URL parms like <url>?parm1=value1&parm2=value2 ...
   * SearchQuery is made from a combination of filter-values objects
   * and receives the limit and offset values for results pagination.
   * Ordering field comes from 'state.orderingField'
   */
  updateSearchQuery: function (state) {
    /**
     * examinates filter content and returns a formatted string for the query
     */
    function getValue(fval) {
      if (fval.getMonth) {
        return fval.toISOString().slice(0, 10)
      }
      if (typeof fval == 'object') {
        return Object.values(fval)[0]
      }
      return fval
    }
    /* making the URL parms for API search */
    state.searchQuery = new URLSearchParams()
    Object.keys(state.appliedFilters).forEach((fk) => {
      const fv = state.appliedFilters[fk]
      /* some filter values are Array */
      if (Array.isArray(fv)) {
        fv.forEach((fval) => {
          const val = getValue(fval)
          // console.debug('BSSQ/FILTERS: fval', fval, typeof fval, val)
          state.searchQuery.append(fk, val)
        })
      } else {
        const val = getValue(fv)
        // console.debug('BSSQ/FILTERS: fv', fv, typeof fv, val)
        state.searchQuery.append(fk, val)
      }
    })
    /* pagination and ordering */
    state.searchQuery.append('ordering', state.orderingField)
    state.searchQuery.append('offset', state.offset * state.limit)
    state.searchQuery.append('limit', state.limit)
  },
  /**
   * This parses the API answer and stores
   * raw response (for more info about query/results)
   * and direct search results.
   * @param {*} response
   */
  setSearchResults: function (state, response) {
    state.searchResponse = response
    state.searchResults = [...response.data.results]
  },
  /**
   * Used to determine a loading/loaded status.
   * @param {*} value
   */
  setResultsReady: function (state, value) {
    state.resultsReady = value
  },
  /**
   * Some models are used as choices sources.
   * This is implemented in modules to adapt model specificities.
   */
  setChoices: function () {},
  /**
   * Show selected results
   * @param {*} value
   */
  setShowSelected(state, value) {
    state.showSelectedResults = value
  },
  /**
   * Permits to put objects in selected list
   * @param {*} result
   */
  toggleSelected(state, result) {
    const cc = state.selectedResults.find((i) => i.id == result.id)
    if (cc) {
      Vue.delete(state.selectedResults, state.selectedResults.indexOf(cc))
    } else {
      state.selectedResults = [...state.selectedResults, result]
    }
  },
  /**
   * Toggles selection of all results.
   *
   * @param {Boolean} addToSelection - if true adds all results to selection.
   * if false or undefined, unselects all items.
   */
  toggleAll(state) {
    if (!state.allSelected) {
      state.selectedResults = [...state.searchResults]
      state.allSelected = true
    } else {
      state.selectedResults = []
      state.allSelected = false
    }
    // if (addToSelection) { // select > intersect
    //   state.selectedResults = [...new Set([...state.selectedResults, ...state.searchResults])]
    // } else { // unselect > exclusion
    //   state.selectedResults = [...state.selectedResults.filter(x => !state.searchResults.includes(x))]
    // }
  },
  /**
   * Remove all selected results from selection.
   */
  // clearSelection(state){
  //   state.selectedResults = []
  // },
  setOrderingField: function (state, value) {
    state.orderingField = value
  },
  setOrderingDesc: function (state, value) {
    state.orderingDesc = value
  },
}

const actions = {
  initStore: function ({ commit, dispatch }, tapApi) {
    commit('setTapApi', tapApi)
    // dispatch('initChoices')
    return dispatch('populateAvailableFields')
  },
  populateAvailableFields: function ({ state, commit }) {
    commit('setFieldsReady', false)
    return state.endpoint.options().then((response) => {
      if (response.data.actions) {
        commit('setAvailableFields', response.data.actions.POST)
      }
      if (response.data.filters) {
        commit('setAvailableFilterFields', response.data.filters)
      }
      commit('setFieldsReady', true)
      console.debug(`${state.modelDisplayNames[1]} store is ready.`)
    })
  },
  resetSearchQuery: function ({ commit, dispatch }) {
    commit('resetFilters')
    // commit('setSearchQuery', {});
  },
  search: async function ({ state, commit }) {
    commit('setResultsReady', false)
    commit('updateSearchQuery')
    // console.debug('SEARCH: start', Object.values(state.appliedFilters))
    state.endpoint.search(state.searchQuery)
      .then((response) => {
        commit('setSearchResults', response)
        commit('setResultsReady', true)
        // console.debug(`SEARCH: got ${response.data.count} results for ${Object.values(state.appliedFilters)}`)
        return true
      })
  },
  setOrdering: function ({ state, commit, dispatch }, { column, desc }) {
    if (desc) {
      column = '-' + column
    }
    // don't update store if ordering option are identical to current
    if (state.orderingField != column) {
      commit('setOrderingField', column)
      commit('setOrderingDesc', desc)
      const nf = {
        key: 'ordering',
        value: column,
      }
      // commit('addSimpleFilter', nf)
      dispatch('search')
    }
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
