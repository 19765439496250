<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.facebook"
      :errors="errors.facebook"
      :required="fields.facebook.required"
      field-type="url"
      label="Facebook page"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseInputContainer
      v-model="item.instagram"
      :errors="errors.instagram"
      :required="fields.instagram.required"
      field-type="url"
      label="Instagram account"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseInputContainer
      v-model="item.twitter"
      :errors="errors.twitter"
      :required="fields.twitter.required"
      field-type="url"
      label="Twitter account"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
  </section>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'

export default {
  name: 'SocialNetworkForm',
  mixins: [modelForms],
  computed: {
    fields: function () {
      return this.$parent.availableFields.social_network.children
    },
  },
}
</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>
</style>
