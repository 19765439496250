<template>
  <section v-if="item">
    <section>
      <BaseInputContainer
        v-model="item.name"
        :errors="addressErrors.name"
        field-type="string"
        label="Address name"
        :required="availableFields.name.required"
        :max-length="availableFields.name.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.address1"
        :errors="addressErrors.address1"
        field-type="string"
        label="Address ligne 1"
        :required="availableFields.address1.required"
        :max-length="availableFields.address1.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.address2"
        :errors="addressErrors.address2"
        field-type="string"
        label="Address ligne 2"
        :required="availableFields.address2.required"
        :max-length="availableFields.address2.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.city"
        :errors="addressErrors.city"
        field-type="string"
        label="Town"
        :required="availableFields.city.required"
        :max-length="availableFields.city.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.postal"
        :errors="addressErrors.postal"
        field-type="string"
        label="Zip code"
        :required="availableFields.postal.required"
        :max-length="availableFields.postal.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.state"
        :errors="addressErrors.state"
        field-type="string"
        label="State"
        :required="availableFields.state.required"
        :max-length="availableFields.state.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.country"
        :errors="addressErrors.country"
        field-type="select"
        reverse-field="code"
        :options="COUNTRY_CHOICES"
        label="Country"
        placeholder="Pick a country..."
        :required="true"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <BaseInputContainer
      v-model="item.phone"
      :errors="addressErrors.phone"
      field-type="string"
      label="Phone"
      :required="availableFields.phone.required"
      :max-length="availableFields.phone.max_length"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseMap
      v-if="!disableMap"
      :item="item"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: 'AddressForm',
  mixins: [modelForms],
  props: {
    /**
     * setting modelName allows to enjoy
     * availableFields correctly computed
     */
    modelName: {
      type: String,
      default: 'address'
    },
    disableMap: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function (){
    return {
      subForm: true
    }
  },
  computed: {
    addressErrors: function(){
      if (this.errors){
        if (this.errors.address){
          return this.errors.address
        } else {
          return this.errors
        }
      } else {
        return {}
      }
    },
  },
}
</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>

</style>
