<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Event</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Event</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Event's informations</h2>
      <section>
        <BaseInputContainer
          v-model="item.name"
          field-type="string"
          label="Event's name"
          :errors="errors.name"
          :required="availableFields.name.required"
          :max-length="availableFields.name.max_length"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.type"
          field-type="select"
          label="Type"
          :options="typeChoices"
          :errors="errors.type"
          :required="availableFields.type.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <BaseInputContainer
        v-model="item.description"
        field-type="string"
        label="Event's description"
        :errors="errors.description"
        :required="availableFields.description.required"
        :max-length="availableFields.description.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <h2>Date</h2>
      <!-- start_date -->
      <BaseInputContainer
        v-model="item.start_date"
        label="Start date"
        field-type="date"
        :required="availableFields.start_date.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- end_date -->
      <BaseInputContainer
        v-model="item.end_date"
        label="End date"
        field-type="date"
        :required="availableFields.end_date.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Participants</h2>
      <BaseInputContainer
        v-model="item.artist_set"
        field-type="multiselect"
        label="Participant artists"
        reverse-field="id"
        :data-source="$TapAdmin.artist"
        :required="availableFields.artist_set.required"
        :errors="errors.artist_set"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <h2>Localization</h2>
      <AddressForm
        :item="item.address"
        :errors="errors.address"
        v-on="$listeners"
      />
    </section>
    <section>
      <!-- media_set -->
      <SetEditor
        :item="item.media_set"
        :errors="errors.media_set"
        label="Event views"
        set-model="media_set"
        @update="onFieldUpdate"
        @input="onFieldInput"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      >
        <template slot="controls">
          <slot name="controls" />
        </template>
      </SetEditor>
      <!-- tag_set -->
    </section>
  </div>
</template>

<script>
// Mixin
import { modelForms } from '@/mixins/modelForms.js'
import SetEditor from '@/components/SetEditor'
import AddressForm from '@/components/AddressForm'

export default {
  name: 'EventForm',
  components: {
    SetEditor,
    AddressForm,
  },
  mixins: [modelForms],
  computed: {
    typeChoices: function () {
      return this.availableFields.type.choices
    },
  },
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
</style>