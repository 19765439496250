<template>
  <div
    id="app"
    :class="[
      'default-theme',
      { 'menu-small': menuSmall },
      { 'small-theme': smallScreen },
      { 'debug-theme': isEnvDebug },
    ]"
  >
    <Modal v-if="modalShow" />
    <TopBarAdmin id="top-bar" />
    <template v-if="isAuthenticated">
      <div id="main-view">
        <MenuAdmin
          id="menu-admin"
          ref="menuAdmin"
        />
        <router-view
          v-if="appReady"
          id="router-view"
        />
        <BaseLoader v-else />
      </div>
    </template>
    <template v-else-if="['Login', 'AccountRedirection'].includes($route.name)">
      <router-view id="router-view" />
    </template>
    <template v-else>
      <Login />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Login from '@/views/Login'
import MenuAdmin from '@/components/MenuAdmin'
import Modal from '@/components/Modal'
import TopBarAdmin from '@/components/TopBarAdmin'

export default {
  name: 'App',
  components: {
    Login,
    MenuAdmin,
    Modal,
    TopBarAdmin,
  },
  data: function () {
    return {
      appReady: false,
    }
  },
  computed: {
    ...mapState('user', {
      isAuthenticated: (state) => state.isAuthenticated,
    }),
    ...mapState('ui', {
      smallScreen: (state) => state.isSmall,
      menuSmall: (state) => state.menuSmall,
      modalShow: (state) => state.modalShow,
    }),
    isEnvDebug: function () {
      return process.env.VUE_APP_TAP_DEBUG == 1
    }
  },
  watch: {
    /**
     * At user store initialisation, isAuthenticated is false.
     * This starts UI loading when changes to true.
     */
    isAuthenticated: function (nv, ov) {
      if (!ov && nv) this.initUI()
    },
  },
  created: async function () {
    console.debug('APP: App created.')
    /* Are we a developper ? */
    if (this.isEnvDebug) {
      console.debug('APP: debug mode env', process.env)
    }
    /* service worker init */
    if (this.$workbox) {
      console.debug('SW: init')
      this.$workbox.addEventListener('waiting', async () => {
        console.debug('SW: ✨🎉NEW VERSION READY, reloading')
        await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
        console.debug('SW: ✨🎉NEW VERSION READY ✨🎉 --------')
        // this.showUpdateUI = true
        location.reload()
      })
    }
  },
  mounted: async function () {
    await this.initApp()
  },
  methods: {
    initApp: async function () {
      console.debug('APP: init ...')
      this.$store.dispatch('user/initModel', this.$TapAdmin.auth)
      this.initLayoutModifiers()
      return this.$store.dispatch('user/checkAuth')
    },
    initUI: async function () {
      this.$store.dispatch('ui/init')
      this.initAllModels()
    },
    initAllModels: async function () {
      // ---- All models initialization ----
      const modelsToInit = [
        'address',
        'article',
        'artist',
        'artwork',
        'cause',
        'country',
        'currency',
        'event',
        'fieldpicture',
        'gallery',
        'institution',
        'organisation',
        'project',
        'tag',
        'transaction',
      ]
      return Promise.all([
        this.$store.dispatch('choices/initStore', this.$TapAdmin),
        ...modelsToInit.map((m) => {
          return this.$store.dispatch(`${m}/initStore`, this.$TapAdmin)
        }),
      ]).then(() => {
        console.debug('APP: ready')
        this.appReady = true
        return true
      })
    },
    initLayoutModifiers: function () {
      this.handleResizeSmall()
      this.handleResizeMobile()
      this.handleOrientationChange()
    },
    /**
     * Handles screen size by comparing to a predefined value.
     */
    handleResizeSmall: function (event) {
      console.debug('APP: matchmedia! small')
      const vpl1 = this.$store.state.ui.viewportMinSizeBeforeSmall
      if (this.mediaQueryListSmall == null) {
        this.mediaQueryListSmall = matchMedia(`(max-width: ${vpl1}px)`)
      }
      this.mediaQueryListSmall.addEventListener(
        'change', this.handleResizeSmall, { once: true }
      )
      this.$nextTick(() => {
        const w = window.innerWidth
        const isSmall = w < vpl1
        if (isSmall) {
          console.debug('APP: resize, %cSMALL', 'font-weight: bold;')
          this.$store.dispatch('ui/setMenuSmall')
        } else {
          this.$store.dispatch('ui/setMenuBig')
        }
        this.$store.commit('ui/setIsSmall', isSmall)
      })
    },
    handleResizeMobile: function (event) {
      console.debug('APP: matchmedia! mobile')
      const vpl2 = this.$store.state.ui.viewportMinSizeBeforeMobile
      if (this.mediaQueryListMobile == null) {
        this.mediaQueryListMobile = matchMedia(`(max-width: ${vpl2}px)`)
      }
      this.mediaQueryListMobile.addEventListener(
        'change',
        this.handleResizeMobile,
        { once: true }
      )
      this.$nextTick(() => {
        const w = window.innerWidth
        const isVerySmall = w < vpl2
        if (isVerySmall) console.debug('APP: resize, %cMOBILE', 'font-weight: bold;')
        this.$store.commit('ui/setIsMobile', isVerySmall)
      })
    },
    handleOrientationChange: function (event) {
      console.debug('APP: matchmedia! orientation')
      if (this.mediaQueryOrientation == null) {
        this.mediaQueryOrientation = matchMedia('(orientation: portrait)')
      }
      this.mediaQueryOrientation.addEventListener(
        'change', this.handleOrientationChange, { once: true }
      )
      this.$nextTick(() => {
        const w = window.innerWidth
        const isSmall = w < this.$store.state.ui.viewportMinSizeBeforeSmall
        const isVerySmall = w < this.$store.state.ui.viewportMinSizeBeforeMobile
        this.$store.commit('ui/setIsSmall', isSmall)
        this.$store.commit('ui/setIsMobile', isVerySmall)
        console.debug(`APP: resize after orientation change, %c${isSmall?'small':''} ${isVerySmall?'mobile':''}`, 'font-weight: bold;')
      })
    },
  },
}
</script>

<style src="@/assets/css/main.css"></style>
<style scoped>
#app {
  /* overflow: hidden; */
}
#main-view {
  position: relative;
  display: flex;
  align-items: stretch;
}
#top-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: var(--topbar-height);
  width: 100%;
  z-index: var(--topbar-z-index);
}
#router-view {
  width: 100%;
  margin-left: 20px;
  box-sizing: border-box;
}
#menu-admin {
  position: sticky;
  top: calc(var(--topbar-height) + var(--margin-unit));
  width: var(--default-menu-width);
  height: 100%;
}
.menu-small #menu-admin {
  width: 100px;
}
</style>

