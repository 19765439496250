<template>
  <tr>
    <!-- see ListView for slot declaration -->
    <slot name="listSelectionControls" />
    <td>
      <strong>{{ object.title }}</strong>
    </td>
    <td>
      <p>{{ object.category.name }}</p>
    </td>
    <!-- ARTIST_SET -->
    <td>
      <p
        v-for="(artist, index) in object.artist_set"
        :key="index"
      >
        <template v-if="object.artist_set.length > 1">
          &middot;
        </template>
        <router-link
          :to="{name: 'artist-view', params: { id: artist.id } }"
          title="Artist details"
        >
          {{ artist.name }}
        </router-link>
      </p>
    </td>
    <!-- GALLERY_SET -->
    <td>
      <p
        v-for="(gallery, index) in object.gallery_set"
        :key="index"
      >
        <template v-if="object.gallery_set.length > 1">
          &middot;
        </template>
        <router-link
          :to="{name: 'gallery-view', params: { id: gallery.id } }"
          title="Gallery details"
        >
          {{ gallery.name }}
        </router-link>
      </p>
    </td>
    <!-- see ListView for slot declaration -->
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'ArticleRow',
  mixins: [modelRowUtil],
};

</script>

<style scoped>
</style>
