<template>
  <div class="filters-fields">
    <BaseInputContainer
      v-model="filterValues.name"
      field-type="string"
      label="Name"
      placeholder="Name"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.country"
      field-type="multiselect"
      :options="COUNTRY_CHOICES"
      reverse-field="code"
      label="Country"
      :validable="false"
      placeholder="Select country"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'OrganisationFilters',
  mixins: [modelFilters],
  data() {
    return {
      model: 'organisation',
    }
  },
};

</script>

<style src="../../assets/css/filters.css" scoped></style>