<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.project_count }}
    </td>
    <td>
      {{ object.country }}
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'OrganisationRow',
  mixins: [modelRowUtil],
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>