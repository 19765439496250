import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['cause', 'causes'],
  modelName: 'cause',
  // causeChoices: []
}

const mutations = {
  ...baseStore.mutations,
  // setChoices: function(state, choicesResults){
  //   const choices = choicesResults.map(c=>{
  //     return {
  //       value: c, 
  //       display_name: `${c.id}. ${c.name}`
  //     }
  //   })
  //   state.causeChoices = [...choices]
  // }
}

const actions = {
  ...baseStore.actions,
}

const getters = {
  ...baseStore.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
