import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminAccountRedirection from '@/views/AdminAccountRedirection'
import Dashboard from '@/views/Dashboard'
import DetailsView from '@/views/DetailsView'
import ListView from '@/views/ListView'
import Login from '@/views/Login'
import PublicSettings from '@/views/PublicSettings'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/settings',
    name: 'public-settings',
    component: PublicSettings,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/account_redirection',
    name: 'AccountRedirection',
    component: AdminAccountRedirection,
    props: (route) => ({
      accountProcess: route.query.process,
      tk1: route.query.tk1,
      tk2: route.query.tk2,
      token: route.query.token,
      ok: route.query.ok,
    }),
  },
  // Artists
  {
    path: '/artists',
    name: 'artist-list',
    component: ListView,
    props: {
      model: 'artist',
    },
  },
  {
    path: '/artist/new',
    name: 'artist-creation',
    component: DetailsView,
    props: {
      modelName: 'artist',
      creation: true,
      id: null,
    },
  },
  {
    path: '/artist/:id',
    name: 'artist-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'artist',
      id: route.params.id,
    }),
  },
  // Artworks
  {
    path: '/artworks',
    name: 'artwork-list',
    component: ListView,
    props: {
      model: 'artwork',
    },
  },
  {
    path: '/artwork/new',
    name: 'artwork-creation',
    component: DetailsView,
    props: {
      modelName: 'artwork',
      creation: true,
      id: null,
    },
  },
  {
    path: '/artwork/:id',
    name: 'artwork-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'artwork',
      id: route.params.id,
    }),
  },
  // Galleries
  {
    path: '/galleries',
    name: 'gallery-list',
    component: ListView,
    props: {
      model: 'gallery',
    },
  },
  {
    path: '/gallery/new',
    name: 'gallery-creation',
    component: DetailsView,
    props: {
      modelName: 'gallery',
      creation: true,
      id: null,
    },
  },
  {
    path: '/gallery/:id',
    name: 'gallery-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'gallery',
      id: route.params.id,
    }),
  },
  // Causes
  {
    path: '/causes',
    name: 'cause-list',
    component: ListView,
    props: {
      model: 'cause',
    },
  },
  {
    path: '/cause/new',
    name: 'cause-creation',
    component: DetailsView,
    props: {
      modelName: 'cause',
      creation: true,
      id: null,
    },
  },
  {
    path: '/cause/:id',
    name: 'cause-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'cause',
      id: route.params.id,
    }),
  },
  // Projects
  {
    path: '/projects',
    name: 'project-list',
    component: ListView,
    props: {
      model: 'project',
    },
  },
  {
    path: '/project/new',
    name: 'project-creation',
    component: DetailsView,
    props: {
      modelName: 'project',
      creation: true,
      id: null,
    },
  },
  {
    path: '/project/:id',
    name: 'project-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'project',
      id: route.params.id,
    }),
  },
  // Organisations
  {
    path: '/organisations',
    name: 'organisation-list',
    component: ListView,
    props: {
      model: 'organisation',
    },
  },
  {
    path: '/organisation/new',
    name: 'organisation-creation',
    component: DetailsView,
    props: {
      modelName: 'organisation',
      creation: true,
      id: null,
    },
  },
  {
    path: '/organisation/:id',
    name: 'organisation-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'organisation',
      id: route.params.id,
    }),
  },
  // Events
  {
    path: '/events',
    name: 'event-list',
    component: ListView,
    props: {
      model: 'event',
    },
  },
  {
    path: '/event/new',
    name: 'event-creation',
    component: DetailsView,
    props: {
      modelName: 'event',
      creation: true,
      id: null,
    },
  },
  {
    path: '/event/:id',
    name: 'event-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'event',
      id: route.params.id,
    }),
  },
  // Institutions
  {
    path: '/institutions',
    name: 'institution-list',
    component: ListView,
    props: {
      model: 'institution',
    },
  },
  {
    path: '/institution/new',
    name: 'institution-creation',
    component: DetailsView,
    props: {
      modelName: 'institution',
      creation: true,
      id: null,
    },
  },
  {
    path: '/institution/:id',
    name: 'institution-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'institution',
      id: route.params.id,
    }),
  },
  // Tags
  {
    path: '/tags',
    name: 'tag-list',
    component: ListView,
    props: {
      model: 'tag',
    },
  },
  {
    path: '/tag/new',
    name: 'tag-creation',
    component: DetailsView,
    props: {
      modelName: 'tag',
      creation: true,
      id: null,
    },
  },
  {
    path: '/tag/:id',
    name: 'tag-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'tag',
      id: route.params.id,
    }),
  },
  /* TRANSACTIONS */
  {
    path: '/transactions',
    name: 'transaction-list',
    component: ListView,
    props: {
      model: 'transaction',
    },
  },
  {
    path: '/transaction/:id',
    name: 'transaction-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'transaction',
      id: route.params.id,
    }),
  },
  // Image for home page grids
  {
    path: '/fieldpictures',
    name: 'fieldpicture-list',
    component: ListView,
    props: {
      model: 'fieldpicture',
    },
  },
  {
    path: '/fieldpicture/new',
    name: 'fieldpicture-creation',
    component: DetailsView,
    props: {
      modelName: 'fieldpicture',
      creation: true,
      id: null,
    },
  },
  {
    path: '/fieldpicture/:id',
    name: 'fieldpicture-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'fieldpicture',
      id: route.params.id,
    }),
  },

  // Articles
  {
    path: '/articles',
    name: 'article-list',
    component: ListView,
    props: {
      model: 'article',
    },
  },
  {
    path: '/article/new',
    name: 'article-creation',
    component: DetailsView,
    props: {
      modelName: 'article',
      creation: true,
      id: null,
    },
  },
  {
    path: '/article/:id',
    name: 'article-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'article',
      id: route.params.id,
    }),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// router.beforeEach((to, from, next) => {
//   window.scrollTo(0,0)
//   next()
// })

export { routes, router }
