<template>
  <div class="filters-fields">
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.title"
        field-type="string"
        label="Title"
        placeholder="Title"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.artist"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.artist"
        label="Artist"
        placeholder="Choose an artist"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.genre"
        field-type="multiselect"
        :options="GENRE_CHOICES"
        label="Genre"
        placeholder="Select genre"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.medium"
        field-type="select"
        :options="MEDIUM_CHOICES"
        label="Medium"
        placeholder="Select medium"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.edition"
        field-type="select"
        :options="EDITION_CHOICES"
        label="Rarity"
        placeholder="Select rarity"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.price"
        field-type="select"
        :options="PRICE_FILTER_CHOICES"
        label="Price"
        placeholder="Select price range"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.gallery"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.gallery"
        label="Gallery"
        placeholder="Choose a gallery"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.cause"
        field-type="multiselect"
        placeholder="Select a cause..."
        label="Causes"
        :options="CAUSE_CHOICES"
        reverse-field="id"
        :validable="false"
        display-id
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.tag"
        field-type="multiselect"
        placeholder="Select tags..."
        reverse-field="id"
        label="Tags"
        :data-source="$TapAdmin.tag"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.size"
        field-type="select"
        :options="SIZE_FILTER_CHOICES"
        label="Size"
        placeholder="Select size"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.delivery"
        field-type="select"
        :options="DELIVERY_CHOICES"
        label="Delivery"
        placeholder="Select delivery"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.available"
        field-type="checkbox"
        label="Available"
        placeholder="Available"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.signed"
        field-type="checkbox"
        label="Signed"
        placeholder="Signed"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.certificate"
        field-type="checkbox"
        label="Certificate"
        placeholder="Certificate"
        :validable="false"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'ArtworkFilters',
  mixins: [modelFilters],
  data: function () {
    return {
      model: 'artwork',
    }
  },
}
</script>
<style src="../../assets/css/filters.css" scoped></style>
