<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ tagCategory }}
    </td>
    <td>
      {{ object.wikipedia }}
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import { modelRowUtil } from '@/mixins/modelRowUtil'

export default {
  name: 'TagRow',
  mixins: [modelRowUtil],
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>