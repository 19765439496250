<template>
  <section v-if="item">
    <section>
      <BaseInputContainer
        v-model="item.type"
        field-type="select"
        label="Type"
        :options="fields.type.choices"
        :errors="errors?errors.type:null"
        :required="fields.type.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.title"
        field-type="string"
        :label="fields.title.label"
        help-text="title of the CV entry"
        :required="fields.title.required"
        :errors="errors?errors.title:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      />
      <BaseInputContainer
        v-model="item.link"
        field-type="url"
        :label="fields.link.label"
        help-text="hyperlink"
        :required="fields.link.required"
        :errors="errors?errors.link:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      />
      <BaseInputContainer
        v-model="item.year"
        label="Year"
        field-type="integer"
        :required="fields.year.required"
        :errors="errors?errors.year:null"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <BaseInputContainer
      v-model="item.description"
      field-type="string"
      :label="fields.description.label"
      help-text="Description of the CV entry"
      :required="fields.description.required"
      :errors="errors?errors.description:null"
      @input="onFieldInput"
      @update="onFieldUpdate"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: 'CurriculumEntryForm',
  mixins: [modelForms],
  props: {},
  computed: {
    fields: function(){
      return this.$parent.$parent.availableFields.curriculumentry_set.child.children
    },
  },
  methods: {

  }
}
</script>

<style src="@/assets/css/forms.css" scoped></style>
<style scoped>
</style>
