<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <img
          v-if="object.portrait"
          :src="object.portrait.thumbnail"
        >
      </div>
    </td>
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.metadata.featured }}
    </td>
    <td>
      <p
        v-for="(artist, index) in object.artist_set.slice(0, 2)"
        :key="index"
      >
        <router-link
          :to="{name: 'artist-view', params: { id: artist.id } }"
          title="Artist details"
        >
          {{ artist.name }}
        </router-link>
      </p>
      <p v-if="object.artist_set.length > 2">
        ...
      </p>
    </td>
    <td>
      {{ object.visibility.tap || "-" }}
    </td>
    <td>
      <p
        v-for="(cause, index) in object.cause_set.slice(0, 2)"
        :key="index"
      >
        <router-link
          :to="{name: 'cause-view', params: { id: cause.id } }"
          title="Cause details"
        >
          {{ cause.name }}
        </router-link>
      </p>
      <p v-if="object.cause_set.length > 2">
        ...
      </p>
    </td>
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'GalleryRow',
  mixins: [modelRowUtil],
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>
