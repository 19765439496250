import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['grid picture', 'grid pictures'],
  modelName: 'fieldpicture',
  choices: []
}

const mutations = {
  ...baseStore.mutations,
}

const actions = {
  ...baseStore.actions,
}

const getters = {
  ...baseStore.getters,
  contentTypeChoices: function (state){
    if (!state.fieldsReady || !state.availableFields.content_type) return []
    return state.availableFilterFields.content_type.choices
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
