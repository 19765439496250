<template>
  <div class="standard-view">
    <section class="full-width-section first-section process-section">
      <!-- TAP PASSWORD RESET PROCESS -->
      <template v-if="accountProcess == 'password_reset'">
        <!-- password reset final step -->
        <template v-if="actionCompleted">
          <header class="reset">
            <h1 class="title-level-3">
              Password changed
            </h1>
            <span class="text-cdt">
              Your password has been changed. You can connect
              <router-link :to="{ name: 'Login' }">here</router-link>.
            </span>
          </header>
        </template>
        <!-- password reset form -->
        <template v-else>
          <header class="reset">
            <h1 class="title-level-3">
              Reset password
            </h1>
            <BaseNonFieldErrors
              v-if="errors"
              :errors="errors"
            />
            <span class="text-cdt">
              Please enter your new password twice:
            </span>
          </header>
          <form autocomplete="on">
            <BaseInputContainer
              v-model="new_password1"
              field-type="password"
              label="New password"
              autocomplete="current-password"
              :strength="newPasswordStrength"
              :errors="errors ? errors.new_password1 : null"
              required
              @input="checkPasswordStrength"
            />
            <BaseInputContainer
              v-model="new_password2"
              field-type="password"
              autocomplete="current-password"
              :errors="errors ? errors.new_password2 : null"
              required
              label="Confirm password"
            />
            <div id="buttonCategory">
              <BaseButton
                label="Reset password"
                @click="onResetPasswordConfirmClick"
              />
            </div>
          </form>
        </template>
      </template>
      <!-- ERROR : PROCESS IS NOT RECOGNIZED -->
      <template v-if="!availableActions.includes(accountProcess)">
        <header>
          <h1 class="title-level-3">
            The action you requested is not available.
          </h1>
        </header>
      </template>
    </section>
  </div>
</template>

<script>
const AVAILABLE_ACTIONS = ['password_reset']

export default {
  name: 'AccountRedirection',
  props: {
    /**
     * kind of redirection (choice in AVAILABLE_ACTIONS)
     */
    accountProcess: {
      type: String,
      required: true,
      default: '',
    },
    /**
     * security tokens
     */
    tk1: {
      type: String,
      required: true,
      default: '',
    },
    tk2: {
      type: String,
      required: true,
      default: '',
    },
    token: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * account_validation process
     * can success or fail. This toggles
     * the adequate message.
     */
    ok: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      /**
       * password_reset process
       * is 2-step : try change password then
       * display a message
       */
      actionCompleted: false,
      availableActions: AVAILABLE_ACTIONS,
      errors: null,
      new_password1: '',
      new_password2: '',
      newPasswordStrength: -1,
    }
  },
  mounted: function () {
    if (this.accountProcess == 'account_validation') {
      document.title = 'TheArtPledge | Welcome to the art pledge !'
    }
    if (this.accountProcess == 'password_reset') {
      document.title = 'TheArtPledge | Reset your password'
    }
  },
  methods: {
    checkPasswordStrength: function (value){
      this.$TapAdmin.auth
        .getPasswordStrength(value)
        .then((response)=>{
          this.newPasswordStrength = Number(response.data.score)
        })
    },
    onResetPasswordConfirmClick: function () {
      const credentials = {
        new_password1: this.new_password1,
        new_password2: this.new_password2,
        tk1: this.tk1,
        tk2: this.tk2,
        token: this.token,
      }
      this.$TapAdmin.auth
        .confirmPasswordReset(credentials)
        .then((r) => {
          this.actionCompleted = true
        })
        .catch((err) => {
          this.errors = { ...err.response.data }
        })
    },
  },
}
</script>
<style scoped>
.process-section {
  text-align: center;
  padding: 20vh 0;
}
.process-section h1 {
  text-align: center;
  margin-bottom: 30px;
}
.welcome h1 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.welcome a,
.reset a {
  text-decoration: underline;
}
.welcome >>> .base-logo {
  width: 200px;
}
.process-section form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: var(--margin-unit) auto;
  max-width: 600px;
}
.errors {
  margin: var(--margin-unit) auto;
}
.base-input-container,
.base-button-container {
  margin-bottom: calc(var(--margin-unit) / 2);
}
#buttonCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
