<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <BaseCauseSquare
          :cause-item="object"
          :hoverable="false"
        />
      </div>
    </td>
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.project_count }}
    </td>
    <td>
      {{ object.artist_count }}
    </td>
    <td>
      {{ object.gallery_count }}
    </td>
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'CauseRow',
  mixins: [modelRowUtil],
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>
<style scoped>
/* overrides base-cause-square sizing */
.thumb-container .base-cause-square.force-color{
  height: 50px;
  width: 50px;
}
</style>