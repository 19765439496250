<template>
  <div class="filters-fields">
    <!-- <BaseInputContainer
      v-model="filterValues.text"
      field-type="string"
      label="Search"
      placeholder="Search"
      :validable="false"
      v-on="$listeners"
    /> -->
    <BaseInputContainer
      v-model="filterValues.country"
      field-type="select"
      :options="COUNTRY_CHOICES"
      reverse-field="code"
      label="Country"
      placeholder="Select country"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.artist"
      field-type="select"
      reverse-field="id"
      :data-source="$TapAdmin.artist"
      label="Artist"
      placeholder="Choose an artist"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.gallery"
      field-type="select"
      reverse-field="id"
      :data-source="$TapAdmin.gallery"
      label="Gallery"
      placeholder="Choose a gallery"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.event"
      field-type="multiselect"
      reverse-field="id"
      :data-source="$TapAdmin.event"
      label="Event"
      placeholder="Choose an event"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.causes"
      field-type="multiselect"
      placeholder="Select a cause..."
      reverse-field="id"
      label="Causes"
      :options="CAUSE_CHOICES"
      :validable="false"
      display-id
      v-on="$listeners"
    />
    <!-- duplicate ? -->
    <!-- <BaseInputContainer
      v-model="filterValues.event"
      field-type="select"
      reverse-field="id"
      :data-source="$TapAdmin.event"
      label="Event"
      placeholder="Choose an event"
      :validable="false"
      v-on="$listeners"
    /> -->
  </div>
</template>

<script>

import {modelFilters} from '@/mixins/modelFilters.js'

export default {
  name: 'InstitutionFilters',
  mixins: [modelFilters],
  data() {
    return {
      model: 'institution',
    }
  },
};

</script>

<style src="../../assets/css/filters.css" scoped></style>
