<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Tag</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Tag</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Tag's informations</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Tag's name"
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.category"
        :errors="errors.category"
        field-type="select"
        label="Tag's category"
        :options="TAG_CATEGORY_CHOICES"
        :required="availableFields.category.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="rte"
        label="Tag's description"
        :required="availableFields.description.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.wikipedia"
        :errors="errors.wikipedia"
        field-type="string"
        label="Tag's wikipedia"
        :required="availableFields.wikipedia.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.picture"
        field-type="image"
        label="Picture"
        help-text="Picture for the tag."
        :errors="errors.picture"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.copyright"
        field-type="rte"
        label="Copyright"
        help-text="Copyright/credits for picture"
        :styles="['small-rte']"
        simple-mode
        :errors="errors?errors.copyright:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      /> 
      <slot name="controls" />
    </section>
  </div>
</template>

<script>
// Mixin
import { modelForms } from '@/mixins/modelForms.js'

// Components

export default {
  name: 'TagForm',
  mixins: [modelForms],
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
