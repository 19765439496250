<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.area"
      field-type="string"
      :required="false"
      :disabled="true"
      label="Continent"
      :errors="errors ? errors.item : null"
      @input="onFieldInput"
      @update="onFieldUpdate"
    />
    <BaseInputContainer
      v-model="item.project"
      field-type="select"
      label="CO₂ Compensation"
      reverse-field="id"
      :required="false"
      :data-source="$TapAdmin.project"
      :errors="errors ? errors.item : null"
      @input="onFieldInput"
      @update="onFieldUpdate"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: 'CO2CompensationForm',
  mixins: [modelForms],
  props: {},
  computed: {
  },
  methods: {

  }
}
</script>

<style src="../assets/css/forms.css" scoped></style>

<style scoped>
section {
    display: flex;
    flex-direction: row;
}
section .base-input-container{
    flex: 1 0 0;
}
.set-add{
    display:none;
}
</style>