<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <img
          v-if="object.picture.thumbnail"
          :src="object.picture.thumbnail"
          class="thumb"
        >
      </div>
    </td>
    <td>
      {{ object.content_type.model }}
    </td>
    <td>
      {{ object.field_name }}
    </td>
    <td>
      {{ fieldValue }}
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'

export default {
  name: 'FieldPictureRow',
  mixins: [modelRowUtil],
  computed: {
    fieldValue: function () {
      return this.$store.getters['choices/getChoiceDisplay']([
        this.object.field_name, this.object.field_value
      ]).display_name
    }
  },
  methods: {},
}
</script>

<style src="@/assets/css/listrows.css" scoped></style>

<style scoped></style>
