<template>
  <div 
    :class="[
      'form',
      {'dirty-form': dirty},
    ]"
  >
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="item.id">
          <h2>Grid picture</h2>
          <h1>
            {{ item.content_type.name }}&nbsp;
            {{ item.field_name }}&nbsp;
            {{ fieldValue }}
          </h1>
        </template>
        <template v-else>
          <h1>You cannot create new pictures</h1>
          <h2>They should all exist already</h2>
        </template>
      </div>
    </header>
    <!-- ---- METADATA FORM ----  -->
    <section v-if="item.id">
      <!-- READ ONLY FIELDS ! -->
      <section>
        <BaseInputContainer 
          v-model="item.content_type.model"
          field-type="string"
          label="Target"
          :disabled="true"
        />
        <BaseInputContainer
          v-model="item.field_name"
          field-type="string"
          label="Criteria"
          :disabled="true"
        />
        <BaseInputContainer 
          v-model="item.field_value"
          field-type="string"
          label="Value"
          :disabled="true"
        />
      </section>
      <section>
        <BaseInputContainer
          v-model="item.picture"
          field-type="image"
          label="Picture"
          help-text="Picture to display in the corresponding grid (probably on the home page)."
          ratio-type="grid-picture"
          :disabled-resize="false"
          :errors="errors.picture"
          @input="onFieldInput"
        />
        <slot name="controls" />
      </section>
    </section>
  </div>
</template>

<script>
// Mixin
import { modelForms } from '@/mixins/modelForms.js'

export default {
  name: 'FieldPictureForm',
  components: {},
  mixins: [modelForms],
  computed: {
    fieldValue: function () {
      return this.$store.getters['choices/getChoiceDisplay']([
        this.item.field_name, this.item.field_value
      ]).display_name
    }
  }
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
</style>
