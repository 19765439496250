<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <img
          v-if="thumbnail"
          :src="thumbnail"
          class="thumb"
        >
      </div>
    </td>
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.artist_set.length }}
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'InstitutionRow',
  mixins: [modelRowUtil],
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thumbnail() {
      if (this.object.media_set != null && this.object.media_set.length > 0) {
        if (this.object.media_set[0].picture) {
          return this.object.media_set[0].picture.thumbnail;
        }
      }
      return null;
    }
  }
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>
