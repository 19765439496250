/* __   _   .--.  .---.  _ .--.  
  [  | | | ( (`\]/ /__\\[ `/'`\] 
  |  \_/ |, `'.'.| \__., | |     
  '.__.'_/ [\__) )'.__.'[___]    STORE */

const state = {
  authEndpoint: {},
  currentUser: {},
  isAuthenticated: false,
}

const mutations = {
  setAuthEndpoint: function(state, endpoint) {
    state.authEndpoint = endpoint
  },
  setCurrentUser: function(state, value) {
    state.currentUser = value
  },
  setIsAuthenticated: function(state, value) {
    state.isAuthenticated = value
  },
}

const actions = {
  checkAuth: async function({ commit }) {
    return state.authEndpoint
      .getUser()
      .then((response) => {
        console.debug('USERSTORE: ✔ user auth ok', response.data)
        commit('setCurrentUser', response.data)
        commit('setIsAuthenticated', true)
        return true
      })
      .catch((error) => {
        console.debug('USERSTORE: ❌ user auth failed', error)
        commit('setIsAuthenticated', false)
        return false
      })
  },
  tryLogin: function({ dispatch }, form) {
    return state.authEndpoint.logIn(form)
      .then(async (response) => {
        console.debug('USERSTORE: ✔ Login success', response)
        await dispatch('checkAuth')
        return response
      })
  },
  logout: function({ commit, dispatch }) {
    return state.authEndpoint.logOut().then((response) => {
      console.debug('USERSTORE: ✔ Logout success 👋', response)
      commit('setCurrentUser', null)
      commit('setIsAuthenticated', false)
    })
  },
  initModel: function({ commit }, endpoint) {
    commit('setAuthEndpoint', endpoint)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
