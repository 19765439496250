<template>
  <div class="filters-fields">
    <BaseInputContainer
      v-model="filterValues.name"
      field-type="string"
      label="Name"
      placeholder="Name"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.category"
      field-type="select"
      :options="TAG_CATEGORY_CHOICES"
      label="Category"
      placeholder="Select category"
      :validable="false"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'TagFilters',
  mixins: [modelFilters],
  data() {
    return {
      model: 'tag',
    }
  },
};

</script>

<style src="../../assets/css/filters.css" scoped></style>