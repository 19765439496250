<template>
  <div class="filters-fields">
    <BaseInputContainer
      v-model="filterValues.name"
      field-type="string"
      label="Name"
      placeholder="Name"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.gender"
      field-type="multiselect"
      :options="GENDER_CHOICES"
      label="gender"
      placeholder="Select gender..."
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.age"
      field-type="select"
      :options="AGE_FILTER_CHOICES"
      label="Age"
      placeholder="Select age..."
      :validable="false"
      v-on="$listeners"
    />
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.status"
        field-type="select"
        :options="availableFilterFields.status.choices || STATUS_CHOICES"
        label="Status"
        placeholder="Status"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.published"
        field-type="checkbox"
        label="Published"
        help-text="Artist page publication status"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.featured"
        field-type="checkbox"
        label="Featured"
        help-text="Artist page «featured» status"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <BaseInputContainer
      v-model="filterValues.country_of_birth"
      field-type="multiselect"
      :options="COUNTRY_CHOICES"
      reverse-field="code"
      label="Country of birth"
      :validable="false"
      placeholder="Select country..."
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.country_of_residence"
      field-type="multiselect"
      :options="COUNTRY_CHOICES"
      reverse-field="code"
      label="Country of residence"
      :validable="false"
      placeholder="Select country..."
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.gallery"
      field-type="multiselect"
      reverse-field="id"
      :data-source="$TapAdmin.gallery"
      label="Gallery"
      :validable="false"
      placeholder="Choose a gallery..."
      v-on="$listeners"
    />
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.tag"
        field-type="multiselect"
        placeholder="Select tags..."
        :data-source="$TapAdmin.tag"
        reverse-field="id"
        label="Tags"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.cause"
        field-type="multiselect"
        :options="CAUSE_CHOICES"
        reverse-field="id"
        placeholder="Select a cause..."
        :validable="false"
        display-id
        label="Causes"
        v-on="$listeners"
      />
      <!-- help-text="Causes" -->
      <!-- TODO : DISABLED UNTIL WORKING -->
      <!-- <BaseInputContainer
        v-model="filterValues.region"
        field-type="multiselect"
        label="Region"
        placeholder="Select region"
        :validable="false"
        v-on="$listeners"
      /> -->
    </div>
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'ArtistFilters',
  mixins: [modelFilters],
  data: function () {
    return {
      modelName: 'artist',
    }
  },
}
</script>

<style src="../../assets/css/filters.css" scoped></style>
