<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Artist</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Artist</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Artist's informations</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Artist's name"
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.gender"
        :errors="errors.gender"
        field-type="select"
        label="Gender"
        :options="GENDER_CHOICES"
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.birthyear"
        :errors="errors.birthyear"
        field-type="integer"
        label="Artist's birthyear"
        :required="availableFields.gender.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.city_of_birth"
        :errors="errors.city_of_birth"
        field-type="string"
        label="City of birth"
        :required="availableFields.city_of_birth.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.country_of_birth"
        :errors="errors.country_of_birth"
        field-type="select"
        :options="COUNTRY_CHOICES"
        label="Country of birth"
        reverse-field="code"
        :required="availableFields.country_of_birth.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.city_of_residence"
        :errors="errors.city_of_residence"
        field-type="string"
        label="City of residence"
        :required="availableFields.city_of_residence.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.country_of_residence"
        :errors="errors.country_of_residence"
        field-type="select"
        :options="COUNTRY_CHOICES"
        label="Country of residence"
        reverse-field="code"
        :required="availableFields.country_of_residence.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.gallery_set"
        :errors="errors.gallery_set"
        field-type="multiselect"
        placeholder="Select a gallery..."
        label="Represented by"
        reverse-field="id"
        :required="availableFields.gallery_set.required"
        :data-source="$TapAdmin.gallery"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.portrait"
        field-type="image"
        label="Portrait"
        help-text="Portrait for this artist."
        ratio-type="portrait"
        :disabled-resize="false"
        :errors="errors.portrait"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.copyright"
        field-type="rte"
        label="Copyright"
        help-text="Copyright/credits for portrait."
        :styles="['small-rte']"
        simple-mode
        :errors="errors?errors.copyright:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      /> 
      <slot name="controls" />
    </section>
    <section>
      <h2>Public informations</h2>
      <BaseInputContainer
        v-model="item.bio"
        field-type="rte"
        label="Artist bio"
        :errors="errors.bio"
        :required="availableFields.bio.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.tag_set"
        :errors="errors.tag_set"
        field-type="multiselect"
        placeholder="Select tags..."
        label="Tags"
        reverse-field="id"
        :data-source="$TapAdmin.tag"
        :required="availableFields.tag_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.website"
        field-type="url"
        label="Artist website"
        :errors="errors.website"
        :required="availableFields.website.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.wikipedia"
        field-type="url"
        label="Wikipedia page"
        :errors="errors.wikipedia"
        :required="availableFields.wikipedia.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <SocialNetworkForm
        :item="item.social_network"
        :errors="errors.social_network"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="item.related_artist_set"
        :errors="errors.related_artist_set"
        label="Related artists"
        help-text="Artists related to this artist"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.artist"
        :required="availableFields.related_artist_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Pledge</h2>
      <BaseInputContainer
        v-model="item.cause_set"
        :errors="errors.cause_set"
        field-type="multiselect"
        placeholder="Select UN goals..."
        label="UN Goals"
        reverse-field="id"
        :options="CAUSE_CHOICES"
        :required="availableFields.cause_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <section>
        <BaseInputContainer
          v-model="item.project_set"
          :errors="errors.project_set"
          field-type="multiselect"
          placeholder="Select a project..."
          label="Projects involving artist"
          reverse-field="id"
          :required="availableFields.project_set.required"
          :data-source="$TapAdmin.project"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.pledge_video"
          field-type="url"
          label="Pledge video"
          :errors="errors.pledge_video"
          :required="availableFields.pledge_video.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <BaseInputContainer
        v-model="item.pledge_text"
        :errors="errors.pledge_text"
        field-type="rte"
        placeholder="Enter a pledge text..."
        label="Pledge Text"
        :required="availableFields.pledge_text.required"
        :max-length="availableFields.pledge_text.max_length"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Visibility</h2>
      <VisibilityForm
        :item="item.visibility"
        :errors="errors.visibility"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>CV</h2>
      <SetEditor
        :item="item.curriculumentry_set"
        :errors="errors.curriculumentry_set"
        label="Curriculum"
        set-model="curriculumentry_set"
        @input="onFieldInput"
        @update="onFieldUpdate"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      >
        <template slot="controls">
          <slot name="controls" />
        </template>
      </SetEditor>
    </section>
  </div>
</template>

<script>
/* model form mixin */
import { modelForms } from '@/mixins/modelForms.js'
/* admin components */
import SetEditor from '@/components/SetEditor'
import VisibilityForm from '@/components/VisibilityForm'
import SocialNetworkForm from '@/components/SocialNetworkForm'

export default {
  name: 'ArtistForm',
  components: {
    SetEditor,
    VisibilityForm,
    SocialNetworkForm,
  },
  mixins: [modelForms],
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
