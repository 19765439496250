<template>
  <div class="filters-fields">
    <BaseInputContainer
      v-model="filterValues.content_type"
      field-type="select"
      label="Target"
      placeholder="Target"
      :options="contentTypeChoices"
      :validable="false"
      v-on="$listeners"
    />
    <BaseInputContainer
      v-model="filterValues.field_name"
      field-type="string"
      label="Critera"
      placeholder="Select criteria"
      :validable="false"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'FieldPictureFilters',
  mixins: [modelFilters],
  data() {
    return {
      model: 'fieldpicture',
    }
  }
};

</script>

<style src="../../assets/css/filters.css" scoped></style>
