<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.currency"
      field-type="select"
      label="Currency"
      reverse-field="code"
      :options="currencyChoices"
      :errors="errors ? errors.item : null"
      required
      @input="onFieldInput"
      @update="onFieldUpdate"
    />
    <BaseInputContainer
      v-model="item.country"
      field-type="select"
      label="Country"
      reverse-field="code"
      :options="COUNTRY_CHOICES"
      :errors="errors ? errors.item : null"
      required
      @input="onFieldInput"
      @update="onFieldUpdate"
    />
    <BaseInputContainer
      v-model="item.account_number"
      field-type="string"
      label="Account number"
      placeholder="Type your IBAN"
      required
      :errors="errors ? errors.item : null"
      @input="onFieldInput"
      @update="onFieldUpdate"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: 'PayoutAccountsForm',
  mixins: [modelForms],
  props: {},
  computed: {
    currencyChoices: function(){
      const currencies = ["EUR", "USD", "GBP"]
      // const choices = this.CURRENCY_CHOICES.filter(c => currencies.indexOf(c.value) != -1)
      const choices = this.CURRENCY_CHOICES.filter((c) => currencies.includes(c.value))
      return choices
    }
  },
  methods: {}
}
</script>

<style src="../../assets/css/forms.css" scoped></style>

<style scoped>
section {
    display: flex;
    flex-direction: row;
}
section .base-input-container{
    flex: 1 0 0;
}
</style>
