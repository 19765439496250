import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['NGO', 'NGO'],
  modelName: 'organisation',
}

const mutations = {
  ...baseStore.mutations,
}

const actions = {
  ...baseStore.actions
}

const getters = {
  ...baseStore.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
