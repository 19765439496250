<template>
  <div 
    :class="[
      'form',
      {'dirty-form': dirty},
    ]"
  >
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Cause</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />   
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Cause</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <slot name="metadata" />
    <!-- <section
      v-if="!$parent.creation"
      class="details"
    >
      <h2>Cause summary</h2>
      <p>Artist count: {{ item.artist_count }}</p>
      <p>Artwork count: {{ item.artwork_count }}</p>
      <p>Gallery count: {{ item.gallery_count }}</p>
      <p>Project count: {{ item.project_count }}</p>
    </section> -->
    <section>
      <h2>Cause's informations</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Cause's name"
        :required="availableFields.name.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.website"
        :errors="errors.website"
        field-type="string"
        label="Cause's website"
        :required="availableFields.website.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Public description</h2>
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="longstring"
        label="Cause's description"
        :required="availableFields.description.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- TODO: INSERT TAG_SET HERE -->
      <slot name="controls" />
    </section>
  </div>
</template>

<script>
// Mixin
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: "CauseForm",
  mixins: [modelForms],
}
</script>


<style src="../../assets/css/forms.css" scoped></style>
<style scoped>

</style>