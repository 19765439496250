import defaultOptions from '../default/options.js'
import { defaultRequestInterceptor } from '../default/interceptors.js'
import DefaultMethods from '../default/methods'
// bindings
import store from '@/store'
import { router } from '@/router'

/* --------------- Service static configuration ------------- */
// Axios requests settings
const config = {
  ...defaultOptions,
  timeout: 8000,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'x-csrftoken',
  withCredentials: true,
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
}

// API base URL
const baseURL = {
  protocol: process.env.VUE_APP_API_PROTOCOL,
  host: process.env.VUE_APP_API_HOST,
  port: process.env.VUE_APP_API_PORT,
  root: process.env.VUE_APP_API_ROOT,
}

// API models URLs
const modelURLs = {
  address: '/admin/common/address/',
  article: '/admin/editorial/article/',
  articlecategory: '/admin/editorial/articlecategory/',
  artist: '/admin/art/artist/',
  artwork: '/admin/art/artwork/',
  cause: '/admin/causes/cause/',
  country: '/common/country/',
  currency: '/common/currency/',
  event: '/admin/art/event/',
  fieldpicture: '/common/modelfieldpicture/',
  gallery: '/admin/art/gallery/',
  institution: '/admin/art/institution/',
  organisation: '/admin/causes/organisation/',
  project: '/admin/causes/project/',
  tag: '/admin/common/tag/',
  transaction: '/admin/transaction/transaction/',
}

// API not-model URLs ("special")
const specialURLs = {
  auth: '/auth/',
  choices: '/choices/',
  conversations: '/admin/conversations/',
  seller: '/admin/account/seller/',
  settings: '/admin/common/settings/',
  system: '/',
  transactionStatus: '/admin/transaction/transaction/',
  // ... add special routes here
}

/* --------------- Interceptors customization ------------ */
const interceptors = {
  request: { ...defaultRequestInterceptor }, // We inject the default interceptors as needed
  response: {
    responseInterceptor: function(response) {
      return response
    },
    errorInterceptor: function(error) {
      // Here we customize response error handling for 404
      // maybe we don't want to Reject 404 errors.
      if (error.response) {
        if (error.response.status === 404) {
          console.error('NOT FOUND', error.config.url, error.config.params)
          return null
        }
        if (error.response.status == 401) {
          console.debug('User is not authentified -- not authorized')
          store.dispatch('user/logout')
          if (!['Login', 'AccountRedirection'].includes(router.history.current.name)){
            router.push({name:'Login'})
          }
        }
      }
      console.error('API error\n', error)
      return Promise.reject(error)
    },
  },
}

/* --------------- Model methods ------------ */
/*  Here the default base CRUD methods are added.
    Custom model methods (other actions than CRUD) can be added in `custom`    */
const modelMethods = (handler, modelName, url) => {
  const baseMethods = new DefaultMethods(handler, modelName, url)
  const defaultChoicesMethods = {
    /**
     * method used in selectors to provide well-formatted choices
     */
    searchFast: (query) =>
      handler.get(`${url}as_choices/`, { params: { ...query, limit: 20 } }),
    /**
     * method used by selectors to prepopulate remoteOptions
     * with correct display_name for already set values
     * @param {Array} query - an Array of form [{field: value}, {field: value}]
     * @returns {Function}
     */
    preloadOptions: function(query) {
      const parms = query.map((pf) => {
        const key = Object.keys(pf)[0]
        const val = Object.values(pf)[0]
        return [key, val]
      })
      /* this makes the URL string query ?field=value&field=value */
      const urlParms = new URLSearchParams(parms)
      return handler.get(`${url}as_choices/`, { params: urlParms })
    },
  }
  const custom = {
    artwork: {
      ...defaultChoicesMethods,
    },
    artist: {
      ...defaultChoicesMethods,
    },
    event: {
      ...defaultChoicesMethods,
    },
    gallery: {
      ...defaultChoicesMethods,
    },
    organisation: {
      ...defaultChoicesMethods,
    },
    institution: {
      ...defaultChoicesMethods,
    },
    project: {
      ...defaultChoicesMethods,
    },
    tag: {
      ...defaultChoicesMethods,
    },
    article: {
      ...defaultChoicesMethods,
    },
    articlecategory: {
      ...defaultChoicesMethods,
    },
  }
  return { ...baseMethods, ...custom[modelName] }
}

/* ---------------- "Special" methods ---------- */
/*  Special methods are API calls that do not need CRUD.
    They still benefit from url prefixing                 */
const specialMethods = (handler, specialName, url) => {
  const special = {
    system: {
      availability: () => handler.options(url),
    },
    choices: () => handler.get(url),
    // ... add special method here
    auth: {
      getUser: () => handler.get(url + 'user/'),
      logIn: (form) => handler.post(url + 'login/', form),
      logOut: () => handler.post(url + 'logout/'),
      getPasswordStrength: (pw) =>
        handler.post(`${url}password_score`, { password: String(pw) }),
      askPasswordReset: (email) =>
        handler.post(`${url}password/reset/`, {email: String(email) }),
      confirmPasswordReset: (payload) =>
        handler.post(`${url}password/reset/confirm/`, payload),
    },
    settings: {
      get: () => handler.get(url),
      patch: (form) => handler.patch(url, form),
    },
    transactionStatus: {
      getTransactionStatusChoices: () => handler.get(`${url}transaction_status_choices/`),
      getShipmentStatusChoices: () => handler.get(`${url}shipment_status_choices/`),
      updateAMLStatus: (id, statusCode) => handler.post(`${url}${id}/update_aml_status/`, {aml_status: statusCode})
    },
    seller: {
      sendActivationEmail: (id) => handler.get(`${url}${id}/send_activation_email`),
    },
    conversations: {
      get: () => handler.get(url)
    },
  }
  return special[specialName]
}

export default {
  config,
  baseURL,
  interceptors,
  modelURLs,
  modelMethods,
  specialURLs,
  specialMethods,
}
