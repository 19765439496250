<template>
  <div class="top-bar-admin">
    <div class="bar-content">
      <router-link
        :to="{ name: 'dashboard' }"
        class="top-bar-logo"
      >
        <BaseLogo :small-screen="smallScreen" />
      </router-link>
      <div
        v-if="isAuthenticated"
        id="whoami"
      >
        <p class="username">
          {{ currentUser.first_name }}
          {{ currentUser.last_name }}
        </p>
        <p class="useremail">
          {{ currentUser.email }}
        </p>
      </div>
      <div id="user-actions">
        <BaseButton
          v-if="isAuthenticated"
          label="Log out"
          @click="onLogoutButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TopBarAdmin',
  data: function () {
    return {}
  },
  computed: {
    ...mapState('ui', {
      smallScreen: (state) => state.isSmall,
    }),
    ...mapState('user', [
      'currentUser', 'isAuthenticated'
    ])
  },
  methods: {
    onLogoutButtonClick: function () {
      this.$store.dispatch('user/logout').then((r) => {
        this.$router.push({ path: '/' })
      })
    },
  },
}
</script>

<style scoped>
.top-bar-admin {
  box-sizing: border-box;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
.bar-content {
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-bar-logo {
  width: 192px;
}
.small-theme .top-bar-logo {
  width: 120px;
}
/* simple rules to visually differentiate prod / preprod / dev */
.debug-theme .top-bar-admin {
  filter: hue-rotate(180deg);
  background: repeating-linear-gradient(
    -45deg,
    #f1f1f1,
    #f1f1f1 10px,
    #f1f1f1 16px,
    #ffffff 20px
  );
}
#user-actions{
  display: flex;
  justify-content: center;
}

.small-theme .bar-content{
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 20px;
}

#whoami {
  text-align: center;
  padding: 20px;
  font-size: 0.8rem;
  margin-left: auto;
  text-decoration: none;
  /* word-break: break-all; */
  word-wrap: break-word;
  /* box-sizing: border-box; */
}
#whoami .username {
  font-family: 'Filson Pro Bold';
}
#whoami .useremail {
  font-family: 'Filson Pro Light Italic';
}
</style>
