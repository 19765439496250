<template>
  <section 
    v-if="item"
    :class="[
      'metadata-form',
      {'fold': fold},
      {'no-fold': !fold}
    ]"
  >
    <header>
      <h2>
        Metadata
      </h2>
      <div 
        class="fold-button"
        @click="fold = !fold"
      >
        <img
          v-if="fold"
          src="../base/assets/img/svgicons/maximize-2.svg"
        >
        <img
          v-else
          src="../base/assets/img/svgicons/minimize-2.svg"
        >
      </div>
      <!-- <div class="details"> -->
      <!-- <p>{{ hintUpdateSeo }} - {{ fold }}</p> -->
      <div
        v-if="hintUpdateSeo"
        class="hint"
      >
        <img src="../base/assets/img/svgicons/alert-triangle.svg">
        Verify SEO informations before publication
      </div>
      
      <!-- </div> -->
    </header>
    <section class="foldable-content">
      <section>
        <h3>Publication</h3>
        <BaseInputContainer
          v-model="item.status"
          placeholder="Choose a status..."
          field-type="select"
          label="Status"
          help-text="Item status (admin)"
          :options="STATUS_CHOICES"
          :required="fields.status.required"
          :errors="errors.status"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.published"
          field-type="checkbox"
          label="published"
          help-text="Publication status"
          :required="fields.published.required"
          :disabled="fields.published.read_only"
          :errors="errors.published"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.pub_date"
          field-type="date"
          label="Publication date"
          help-text="Date used for 'recent' sort"
          :required="fields.pub_date.required"
          :disabled="fields.pub_date.read_only"
          :errors="errors.pub_date"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.featured"
          field-type="integer"
          label="featured"
          help-text="Featured"
          :required="fields.featured.required"
          :errors="errors.featured"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <section>
        <h3>SEO</h3>
        <div class="column">
          <BaseInputContainer
            v-model="item.seo_title"
            field-type="string"
            :label="fields.seo_title.label"
            :help-text="fields.seo_title.help_text"
            :required="fields.seo_title.required"
            :max-length="fields.seo_title.max_length"
            :errors="errors.seo_title"
            @update="onFieldUpdate"
            @input="onFieldInput"
          />
          <BaseInputContainer
            v-model="item.seo_description"
            field-type="longstring"
            :label="fields.seo_description.label"
            :help-text="fields.seo_description.help_text"
            :required="fields.seo_description.required"
            :max-length="fields.seo_description.max_length"
            :errors="errors.seo_description"
            @update="onFieldUpdate"
            @input="onFieldInput"
          />
        </div>
        <div class="column">
          <BaseInputContainer
            v-model="item.seo_picture"
            field-type="image"
            label="Picture (SEO)"
            :required="fields.seo_picture.required"
            :errors="errors.seo_picture"
            help-text="You can add PNG & JPG files. 500px minimum. 20MB maximum."
            @input="onFieldInput"
          />
        </div>
      </section>
      <slot />
    </section>
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'
// import { mapState } from 'vuex'

export default {
  name: "MetadataForm",
  mixins: [modelForms],
  props: {
    hintUpdateSeo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function (){
    return {
      fold: true,
    }
  },
  computed: {
    fields: function (){
      return this.$parent.availableFields.metadata.children
    },
  },
  mounted: function(){
    // Show errors if metadata errors.
    if (Object.keys(this.errors).length){this.fold = false}
  }
}
</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>
.metadata-form{
  border: var(--default-admin-border);
  padding: 10px;
  margin-bottom: var(--field-v-margin);
}
.metadata-form.fold{
  align-items: center;
}
.metadata-form header h2{
  margin-bottom: var(--field-v-margin);
}
.metadata-form.fold header h2{
  margin-bottom: 0px;
}
.fold .foldable-content{
  overflow: hidden;
  margin: 0px;
  animation: .1s fold ease-in-out forwards;
}
.no-fold .foldable-content{
  overflow: visible;
  animation: .1s unfold ease-in-out forwards;
}
header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

h3{
  display: flex;
  align-items: center;
  /* width: 30%; */
}
.fold-button{
  /* margin: 0 10px; */
  cursor: pointer;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fold-button img{
  width: 20px;
}
.fold-button:hover{
  filter: invert(1);
  box-shadow: 0px 0px 5px #707070;
}
.hint{
  font-family: 'Rubik Regular';
  display: flex;
  align-items: center;
  background-color: #fff;
  animation: 1s flashout;
}
.hint img{
  width: 20px;
  margin: 20px;
}
@keyframes flashout{
  0%{ filter: invert(1); }
  100%{ filter: invert(0); }
}
@keyframes fold{
  0%{ height: auto; }
  1%{ height: 800px; }
  100%{ height: 0px; }
}
@keyframes unfold{
  0%{ height: 0px; }
  99%{ height: 800px; }
  100%{ height: auto; }
}
</style>
