import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['gallery', 'galleries'],
  modelName: 'gallery',
}

const mutations = {
  ...baseStore.mutations,
}

const actions = {
  ...baseStore.actions,
}

const getters = {
  ...baseStore.getters,
  /* NO CHOICES PROVIDED BY API 14/09/21 */
  cityChoices: function (state){
    if (!state.fieldsReady) return []
    return state.availableFilterFields.city.choices
  },
  regionChoices: function (state){
    if (!state.fieldsReady) return []
    return state.availableFilterFields.region.choices
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
