<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <img
          v-if="object.media_set && object.media_set.length > 0"
          :src="object.media_set[0].picture.thumbnail"
        >
      </div>
    </td>
    <td>
      <p>
        <span class="title">{{ object.title }}</span>, {{ object.year }}
      </p>
    </td>
    <td>
      {{ object.metadata.featured }}
    </td>
    <td>
      <router-link
        title="Go to artist details"
        :to="{ name: 'artist-view', params: { id: object.artist.id } }"
      >
        {{ object.artist.name }}
      </router-link>
    </td>
    <td>
      <router-link
        title="Go to gallery details"
        :to="{ name: 'gallery-view', params: { id: object.gallery.id } }"
      >
        {{ object.gallery.name }}
      </router-link>
    </td>
    <td>
      <span
        v-for="(cause, index) in object.cause_set"
        :key="index"
      >
        &middot;
        <router-link
          :title="cause.name"
          :to="{ name: 'cause-view', params: { id: cause.id } }"
        >
          {{ cause.id }}
        </router-link>
      </span>
    </td>
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'
export default {
  name: 'ArtworkRow',
  mixins: [modelRowUtil],
}
</script>


<style src="@/assets/css/listrows.css" scoped></style>

