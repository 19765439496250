<template>
  <!-- TODO: add comments in HTML template -->
  <!-- TODO: encapsulate in form as the other forms  -->
  <!-- TODO: benefit from item/itemRaw by copying the standard form behaviour -->
  <!-- TODO: Revise errors management (no prop modification) -->
  <div class="list-view">
    <header class="form-header">
      <h1>Public Settings</h1>
    </header>
    <section
      :class="['set-editor',
               {'fold': fold},
               {'no-fold': !fold}
      ]"
    >
      <header class="homepage-header">
        <h2>Homepage settings</h2>
        <div 
          class="fold-button"
          @click="fold = !fold"
        >
          <img
            v-if="fold"
            src="../base/assets/img/svgicons/maximize-2.svg"
          >
          <img
            v-else
            src="../base/assets/img/svgicons/minimize-2.svg"
          >
        </div>
      </header>
      <div class="foldable-content">
        <div class="carousel-field">
          <h3>Carousel settings</h3>
          <template v-for="(fieldName, fieldIdx) in carouselSettingsFields">
            <template v-if="fieldName == 'cause'">
              <BaseInputContainer
                :key="`psf-${fieldIdx}`"
                v-model="publicSettings.cause_set"
                field-type="multiselect"
                label="UN Goals"
                placeholder="'Select an UN Goal"
                reverse-field="id"
                :options="CAUSE_CHOICES"
                :errors="errors.cause_set"
                @update="onFieldUpdate"
                @input="onFieldInput"
              />
            </template>
            <template v-else>
              <BaseInputContainer
                :key="`psf-${fieldIdx}`"
                v-model="publicSettings[`${fieldName}_set`]"
                field-type="multiselect"
                :label="fieldName"
                :placeholder="`Select a ${fieldName}`"
                reverse-field="id"
                :data-source="$TapAdmin[`${fieldName}`]"
                :errors="errors[`${fieldName}_set`]"
                @update="onFieldUpdate"
                @input="onFieldInput"
              />
            </template>
          </template>
        </div>
        <BaseInputContainer
          v-model="publicSettings.un_goal_of_the_month"
          field-type="integer"
          :required="false"
          label="UN Goal Of The Month"
          help-text="Use the SDG integer value between 1 and 17"
          :errors="errors ? errors.un_goal_of_the_month : null"
          @update="onFieldUpdate"
          @input="onFieldInput"
          @keypress.enter="updatePublicSettings"
        />
      </div>
    </section>
    <section class="controls">
      <BaseButton
        label="Cancel"
        @click="cancelForm"
      />
      <BaseButton
        label="Save"
        :styles="['CTA-nav']"
        @click="updatePublicSettings"
      />
    </section>
    <section>
      <SetEditor
        :item="publicSettings.co2compensationproject_set"
        :errors="errors.publicSettings"
        label="Co₂ Compensation projects"
        set-model="co2_compensation_set"
      />
      <BaseInputContainer
        v-model="publicSettings.co2_ton_price_in_eur"
        field-type="integer"
        :required="true"
        label="CO2 ton price in EUR"
        :errors="errors ? errors.co2_ton_price_in_eur : null"
        @update="onFieldUpdate"
        @input="onFieldInput"
        @keypress.enter="updatePublicSettings"
      />        
    </section>
    <section class="controls">
      <BaseButton
        label="Cancel"
        @click="cancelForm"
      />
      <BaseButton
        label="Save"
        :styles="['CTA-nav']"
        @click="updatePublicSettings"
      />
    </section>
  </div>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'

import SetEditor from '@/components/SetEditor'

const CAROUSEL_SETTINGS_FIELDS = [
  'artwork',
  'project',
  'artist',
  'cause',
  'gallery',
  // 'event',
  //'institution',
]

export default {
  name: 'Settings',
  components: {
    SetEditor,
  },
  mixins: [modelForms],
  data: function () {
    return {
      /* init your settings here */
      publicSettings: {
        un_goal_of_the_month: 0,
      },
      carouselSettingsFields: CAROUSEL_SETTINGS_FIELDS,
      fold: true,
    }
  },
  computed: {},
  mounted: async function () {
    await this.getPublicSettings()
    document.title = 'TAP-Public settings'
  },
  methods: {
    /*
     * Get all settings from API and put them in publicSettings
     */
    getPublicSettings: async function () {
      return this.$TapAdmin.settings.get()
        .then((response) => {
          this.publicSettings = response.data
          return true
        })
    },
    /*
     * if user change value of the form, it will update them in publicSettings
     * publicSettings contains all value of the form
     */
    updatePublicSettings: function () {
      this.$TapAdmin.settings
        .patch(this.publicSettings)
        .then((response) => {
          this.publicSettings = response.data
          // this.itemRaw = response.data
          // this.dirty = false
          this.$store.dispatch('ui/openModal', {
            title: 'Update success',
            message: 'Changes have been saved.',
            theme: 'success',
          })
        })
        .catch((err) => {
          this.errors = { ...err.response.data }
        })
    },
    cancelForm: function () {
      this.getPublicSettings()
    },
  },
}
</script>

<style src="@/assets/css/forms.css" scoped></style>

<style scoped>
section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: var(--field-v-margin);
  margin-right: calc(-1 * var(--field-h-margin));
}
section.controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0px 0px 20px 0px;
}
section.controls .base-button-container {
  margin-right: 20px;
}
.carousel-field {
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: var(--set-pd-u);
  width: calc(100% - var(--set-pd-u));
}
.homepage-header {
  display: flex;
  width: calc(100% - var(--set-pd-u));
}
.homepage-header h2 {
  margin-bottom: 0px;
}
.no-fold .homepage-header {
  margin-bottom: var(--margin-unit);
}
.carousel-field h3 {
  width: 100%;
  padding-top: 5px;
  border-top: 1px solid #CCC;
}
</style>
