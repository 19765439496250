!<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <!-- TODO: organize the form well -->
    <!-- TODO : check help texts, labels -->
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>
            NGO Project
          </h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>NGO Project</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <!-- <section
      v-if="!$parent.creation"
      class="details"
    >
      <h2>Project summary</h2>
      <p>Artist count: {{ item.artist_count }}</p>
      <p>Artwork count: {{ item.artwork_count }}</p>
      <p>Gallery count: {{ item.gallery_count }}</p>
      <p v-if="item.cause_set">
        Cause count: {{ item.cause_set.length }}
      </p>
    </section> -->
    <section>
      <h2>General informations</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        :required="availableFields.name.required"
        label="Project name"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.website"
        :errors="errors.website"
        field-type="string"
        :required="availableFields.website.required"
        label="Project website"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.organisation"
        :errors="errors.organisation"
        field-type="select"
        reverse-field="id"
        label="Organisation"
        :data-source="$TapAdmin.organisation"
        :required="availableFields.organisation.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.active"
        :errors="errors.active"
        :required="availableFields.active.required"
        field-type="checkbox"
        label="Active"
      />
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="rte"
        :required="availableFields.description.required"
        label="Project description"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <SocialNetworkForm
        :item="item.social_network"
        :errors="errors.social_network"
        v-on="$listeners"
      />
      <slot name="controls" />
      <BaseInputContainer
        v-model="item.picture"
        label="Project's picture"
        :errors="errors.picture"
        help-text="You can add PNG & JPG files. 500px minimum. 20MB maximum."
        field-type="image"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.copyright"
        field-type="rte"
        label="Copyright"
        help-text="Copyright/credits for project's picture"
        :styles="['small-rte']"
        simple-mode
        :errors="errors?errors.copyright:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      /> 
      <slot name="controls" />
    </section>
    <section>
      <h2>Pledge</h2>
      <template v-if="item.source == 'IMP'">
        <BaseInputContainer
          v-model="item.external_source.platform"
          field-type="string"
          label="Source platform"
          required
          disabled
        />
      </template>
      <template v-else>
        <BaseInputContainer
          v-model="item.source"
          :errors="errors.source"
          field-type="select"
          placeholder="Select a source"
          label="Source platform"
          :options="PROJECT_SOURCE_CHOICES"
          :required="availableFields.source.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </template>
      <BaseInputContainer
        v-model="item.cause_set"
        :errors="errors.cause_set"
        field-type="multiselect"
        placeholder="Select a cause..."
        reverse-field="id"
        label="Causes"
        display-id
        :options="CAUSE_CHOICES"
        :required="availableFields.cause_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.main_cause"
        :errors="errors.main_cause"
        field-type="select"
        placeholder="Select a cause..."
        reverse-field="id"
        display-id
        label="Main cause"
        :options="CAUSE_CHOICES"
        :required="availableFields.main_cause.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.country_set"
        :errors="errors.country_set"
        field-type="multiselect"
        placeholder="Select a country..."
        label="Countries of involvement"
        reverse-field="code"
        :options="COUNTRY_CHOICES"
        :required="availableFields.country_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <SetEditor
      :item="item.address_set"
      :errors="errors.address_set"
      label="Addresses (for taxes)"
      set-model="address_set"
      @input="onFieldInput"
      @update="onFieldUpdate"
      v-on="$listeners"
      @dirtysubform="onDirtySubform"
    >
      <template slot="controls">
        <slot name="controls" />
      </template>
    </SetEditor>
  </div>
</template>

<script>
// Forms mixin
import { modelForms } from '@/mixins/modelForms.js'
// SpecialForms
import SetEditor from '@/components/SetEditor'
import SocialNetworkForm from '@/components/SocialNetworkForm'


export default {
  name: 'ProjectForm',
  components: {
    SetEditor,
    SocialNetworkForm,
  },
  mixins: [modelForms],
  props: {},
  methods: {},
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
