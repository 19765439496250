<template>
  <div class="filters-fields">
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.title"
        field-type="string"
        label="Title"
        placeholder="Title"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.content"
        field-type="string"
        label="Content"
        placeholder="Content"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.published"
        field-type="checkbox"
        label="Published"
        help-text="Article page publication status"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.cause"
        field-type="multiselect"
        :options="CAUSE_CHOICES"
        reverse-field="id"
        placeholder="Select a cause..."
        help-text="THIS IS NOT WORKING CAUSE THERE IS NO MULTIPLE"
        :validable="false"
        label="Causes"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.gallery"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.gallery"
        label="Gallery"
        :validable="false"
        placeholder="Choose a gallery"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.artist"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.artist"
        label="Artist"
        :validable="false"
        placeholder="Choose an artist"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.artwork"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.artwork"
        label="Artwork"
        :validable="false"
        placeholder="Choose an artwork"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.project"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.project"
        label="Project"
        :validable="false"
        placeholder="Choose a project"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.institution"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.institution"
        label="Institution"
        :validable="false"
        placeholder="Choose an institution"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.event"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.event"
        label="Event"
        :validable="false"
        placeholder="Choose an event"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'ArticleFilters',
  mixins: [modelFilters],
  data: function () {
    return {
      modelName: 'article',
    }
  },
}
</script>

<style src="../../assets/css/filters.css" scoped></style>
