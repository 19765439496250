class BaseModel {
  constructor (handler, modelName, url) {
    this.handler = handler
    this.modelName = modelName
    this.baseURL = url
  }

  options = () => this.handler.options(this.baseURL)
  search = (query) => this.handler.get(this.baseURL, { params: query })
  getByID = (id) => this.handler.get(this.baseURL + id + '/')
  optionsByID = (id) => this.handler.options(this.baseURL + id + '/')
  create = (payload) => this.handler.post(this.baseURL, payload)
  update = (id, payload) => this.handler.patch(this.baseURL + id + '/', payload)
  destroy = (id) => this.handler.delete(this.baseURL + id + '/')
  addComment = (id, payload) => this.handler.post(this.baseURL + id + '/comments/', payload)
  getComments = (id) => this.handler.get(this.baseURL + id + '/comments/')
  changeConversationStatus = (id, payload) => this.handler.post(this.baseURL + id + '/update_conversation_status/', payload)
}

export default BaseModel
