<template>
  <tr v-if="object.order">
    <!-- see ListView for slot declaration -->
    <slot name="listSelectionControls" />
    <td>
      <!-- TODO: get display name -->
      {{ object.status }}
    </td>
    <td>
      {{ object.total_price | formatPrice(object.currency.code) }}
    </td>
    <td>
      {{ object.order.artwork.artist.name }}<br>
      <span class="artwork">{{ object.order.artwork.title | truncate(20) }}</span>, {{ object.order.artwork.year }}<br>
      {{ object.order.artwork.gallery.name }}
    </td>
    <td>
      {{ object.order.buyer.first_name }} {{ object.order.buyer.last_name }}
    </td>
    <td>
      {{ object.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
    </td>
    <td>
      <!-- payment link -->
      <template v-if="stripePaymentDetailsURL">
        <a
          :href="stripePaymentDetailsURL"
          target="_blank"
        >
          <BasePartnerLogo
            partner-name="stripe"
            class="inline"
          />&nbsp;
          <img
            class="link-icon"
            src="@/base/assets/img/svgicons/link.svg"
          >
        </a>
      </template>
      <template v-else>
        No payment
      </template>
    </td>
    <!-- see ListView for slot declaration -->
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'

export default {
  name: 'TransactionRow',
  mixins: [modelRowUtil],
  computed: {
    // transactionStatus: function () {
    //   const choice = this.$store.state.choices.
    // }
    stripePaymentDetailsURL: function () {
      const STRIPE_ROOT_URL =
        'https://dashboard.stripe.com/test/connect/accounts/'
      if (this.object.payment != null) {
        const acct = this.object.payment.connect_id
        const pi = this.object.payment.stripe_id
        return `${STRIPE_ROOT_URL}${acct}/payments/${pi}`
      } else {
        return null
      }
    },
  },
}
</script>

<style scoped>
.link-icon {
  height: 1rem;
}
</style>

<style src="@/assets/css/listrows.css" scoped></style>
<style scoped>
.artwork {
font-family: "Rubik Medium Italic";
}
</style>
