<template>
  <div class="filters-fields">
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.transaction_status"
        field-type="select"
        :options="TRANSACTION_STATUS_CHOICES"
        label="Status"
        placeholder="Select a status..."
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.delivery"
        field-type="select"
        label="Delivery mode"
        placeholder="Select a delivery mode..."
        :options="DELIVERY_CHOICES"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.artist"
        field-type="select"
        :data-source="$TapAdmin.artist"
        placeholder="Choose an artist"
        label="Artist"        
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.gallery"
        field-type="select"
        :data-source="$TapAdmin.gallery"
        label="Gallery"
        :validable="false"
        placeholder="Choose a gallery"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.buyer"
        field-type="string"
        label="Buyer's last name"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.date_after"
        field-type="date"
        label="Date after"
        placeholder="Select a date from..."
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.date_before"
        field-type="date"
        label="Date before"
        placeholder="Select a date to..."
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.price_min"
        field-type="integer"
        label="Minimum price"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.price_max"
        field-type="integer"
        label="Maximum price"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <!-- <div class="row">
      <BaseInputContainer
        v-model="filterValues.institution"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.institution"
        label="Institution"
        :validable="false"
        placeholder="Choose an institution"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.event"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapAdmin.event"
        label="Event"
        :validable="false"
        placeholder="Choose an event"
        v-on="$listeners"
      />
    </div> -->
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'TransactionFilters',
  mixins: [modelFilters],
  data: function () {
    return {
      modelName: 'transaction',
    }
  },
}
</script>

<style src="../../assets/css/filters.css" scoped></style>
