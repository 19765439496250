<template>
  <div class="standard-view">
    <section class="centered-container">
      <header>
        <h1 class="title-level-3">
          {{ formTitle }}
        </h1>
      </header>
      <BaseNonFieldErrors
        v-if="errors"
        :errors="errors"
      />
      <!-- ------ PASSWORD RESET FORM ------ -->
      <form
        v-if="isResetPasswordForm"
        autocomplete="on"
        @submit="onResetPasswordClick"
      >
        <template v-if="!formSubmitted">
          <BaseInputContainer
            key="signin-address"
            v-model="emailAddressResetPassword"
            field-type="email"
            autocomplete="email"
            :errors="errors ? errors.email : null"
            required
            help-text="Email address provided at account creation"
            label="email"
          />
          <div>
            <BaseButton
              label="Send email"
              @click="onResetPasswordClick"
            />
          </div>
        </template>
        <template v-else>
          <span
            class="text-cdt"
          >
            If this address exists, you will receive an email with instructions
            to reset your password.
          </span>
        </template>
        <div class="alternatives">
          <p
            class="text-cdt"
            @click="swapForm"
          >
            Log in
          </p>
        </div>
      </form>
      <!-- ------ STANDARD LOGIN FORM ------ -->
      <form
        v-else
        autocomplete="on"
      >
        <BaseInputContainer
          key="reset-address"
          v-model="emailAddress"
          field-type="email"
          autocomplete="email"
          :errors="errors ? errors.email : null"
          required
          help-text="Email address provided at account creation"
          label="email"
          @keypress.enter="onLoginClick"
        />
        <BaseInputContainer
          v-model="password"
          field-type="password"
          autocomplete="current-password"
          :styles="['password']"
          :errors="errors ? errors.password : null"
          required
          label="password"
          @keypress.enter="onLoginClick"
        />
        <div>
          <BaseButton
            label="Log In"
            @click="onLoginClick"
          />
        </div>
        <div class="alternatives">
          <p
            class="text-cdt"
            @click="swapForm"
          >
            Forgotten password?
          </p>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  props: {
    nextTo: {
      type: Object,
      required: false,
      default: () => {
        return { path: '/' }
      },
    },
  },
  data: function () {
    return {
      errors: null,
      formTitle: 'Log In',
      userName: '',
      emailAddress: '',
      emailAddressResetPassword: '',
      isResetPasswordForm: false,
      formSubmitted: false,
      password: '',
    }
  },
  computed: {},
  mounted: function (){
    document.title = 'The Art Pledge | Login to your account'
  },
  methods: {
    onLoginClick: function () {
      const credentials = {
        username: this.userName,
        email: this.emailAddress,
        password: this.password,
      }
      this.$store
        .dispatch('user/tryLogin', credentials)
        .then((r) => {
          this.$router.push(this.nextTo)
        })
        .catch((err) => {
          console.debug('error', err.response.data)
          this.errors = { ...err.response.data }
        })
    },
    onResetPasswordClick: function (event) {
      if (event){
        event.preventDefault()
      }
      const credentials = { email: this.emailAddressResetPassword }
      this.$TapAdmin.auth
        .askPasswordReset(credentials.email)
        .then((r) => {
          this.formSubmitted = true
        })
        .catch((err) => {
          console.debug('error', err.response.data)
          this.errors = { ...err.response.data }
        })
    },
    swapForm: function () {
      this.isResetPasswordForm = !this.isResetPasswordForm
      if (this.isResetPasswordForm){
        this.formTitle = 'Password recovery'
        this.errors = null
      } else {
        this.formTitle = 'Log In'
        this.errors = null
        this.formSubmitted = false
      }
      document.title = `The Art Pledge | ${this.formTitle}`
    },
  },
}
</script>
<style scoped>
.standard-view {
  width: 100%;
  height: calc(100vh - 108px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-left: -20px; compensates router-view gutter */
}
h1 {
  text-align: center;
  margin-bottom: 30px;
}
form {
  text-align: center;
}
.centered-container {
  display: flex;
  flex-direction: column;
  width: min(618px, 80vw);
  margin-top: -108px;
}
.base-input-container {
  margin-bottom: calc(var(--margin-unit) / 2);
}
.alternatives {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--margin-unit) / 2);
}
.alternatives a,
.alternatives p {
  text-decoration: underline;
  opacity: 0.5;
  cursor: pointer;
  padding: 0 10px;
}
</style>
