<template>
  <tr>
    <slot name="listSelectionControls" />
    <td>
      <div class="thumb-container">
        <img
          v-if="object.picture"
          :src="object.picture.thumbnail"
        >
      </div>
    </td>
    <td>
      {{ object.name }}
    </td>
    <td>
      {{ object.metadata.featured }}
    </td>
    <td>
      <span
        v-for="(cause, index) in object.cause_set"
        :key="index"
      >
        &middot;
        <router-link
          :title="`${cause.name} details`"
          :to="{name: 'cause-view', params: { id: cause.id }}"
        >
          {{ cause.id }}
        </router-link>
      </span>
    </td>
    <td>
      {{ object.artist_count }}
    </td>
    <td>
      {{ object.gallery_count }}
    </td>
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>

import {modelRowUtil} from '@/mixins/modelRowUtil'

export default {
  name: 'ProjectRow',
  mixins: [modelRowUtil],
};

</script>

<style src="@/assets/css/listrows.css" scoped></style>
