<!-- TODO : ajouter tous les helptext -->
<template>
  <div 
    :class="[
      'form',
      {'dirty-form': dirty},
    ]"
  >
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Artwork</h2>
          <h1>{{ itemRaw.title }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
        <template v-else>
          <h2>Create new</h2>
          <h1>Artwork</h1>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <!-- ---------- PLEDGE details --------- -->
    <!-- TODO: decide cause_set control for artwork  -->
    <section>
      <h2>Pledge</h2>
      <BaseInputContainer
        v-model="item.cause_set"
        :errors="errors.cause_set"
        field-type="multiselect"
        placeholder="Select a cause..."
        label="Causes"
        reverse-field="id"
        display-id
        :options="CAUSE_CHOICES"
        :required="availableFields.cause_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
      <!-- <div class="cause-set-display">
        <h3>Causes related</h3>
        <template v-for="(cause, causeIdx) in item.cause_set">
          <p :key="'cause'+causeIdx">
            - {{ cause.name }}
          </p>
        </template>
      </div> -->
    </section>
    <!-- ---------- Artwork INFROMATIONS --------- -->
    <section>
      <h2>Artwork's informations</h2>
      <section>
        <!-- ARTIST -->
        <BaseInputContainer
          v-model="item.artist"
          :errors="errors.artist"
          label="Artist"
          help-text="Artist author of the artwork"
          field-type="select"
          reverse-field="id"
          :data-source="$TapAdmin.artist"
          :required="availableFields.artist.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.gallery"
          :errors="errors.gallery"
          field-type="select"
          placeholder="Select a gallery..."
          label="Gallery"
          reverse-field="id"
          required
          :data-source="$TapAdmin.gallery"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <section>
        <!-- TITLE -->
        <BaseInputContainer
          v-model="item.title"
          :errors="errors.title"
          field-type="string"
          label="title"
          placeholder="Type a title..."
          :required="availableFields.title.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <!-- ARTWORK YEAR -->
        <BaseInputContainer
          v-model="item.year"
          :errors="errors.year"
          field-type="string"
          label="Artwork's year"
          placeholder="Enter a year..."
          :required="availableFields.year.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />        
      </section>
      <!-- TECHNIQUE -->
      <BaseInputContainer
        v-model="item.technique"
        :errors="errors.technique"
        field-type="string"
        label="technique"
        placeholder="Type a medium description..."
        help-text="Detailed medium description"
        :required="availableFields.technique.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- MEDIUM CHOICE -->
      <BaseInputContainer
        v-model="item.medium"
        :errors="errors.medium"
        field-type="select"
        label="Medium"
        placeholder="Choose a medium..."
        :options="MEDIUM_CHOICES"
        :required="availableFields.medium.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- FORMAT CHOICE -->
      <BaseInputContainer
        v-model="item.format"
        :errors="errors.format"
        field-type="select"
        label="Format"
        placeholder="Choose a format..."
        :options="FORMAT_CHOICES"
        :required="availableFields.format.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- GENRE CHOICE -->
      <BaseInputContainer
        v-model="item.genre"
        :errors="errors.genre"
        field-type="select"
        label="Genre"
        placeholder="Choose a genre..."
        :options="GENRE_CHOICES"
        :required="availableFields.genre.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- TODO: handle movement -->
      <!-- <BaseInputContainer
        v-model="item.movement"
        :errors="errors.movement"
        field-type="select"
        label="Movement"
        :options="movementChoices"
        :required="availableFields.movement.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      /> -->
    </section>
    <section>
      <h2>Public description</h2>
      <!-- ARTWORK DESCRIPTION -->
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="longstring"
        label="Description"
        placeholder="Type a description..."
        :required="availableFields.description.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.tag_set"
        :errors="errors.tag_set"
        field-type="multiselect"
        placeholder="Select tags..."
        label="Tags"
        reverse-field="id"
        :data-source="$TapAdmin.tag"
        :required="availableFields.tag_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <!-- ---------- EDITION  details --------- -->
    <section>
      <h2>Edition</h2>
      <BaseInputContainer
        v-model="item.edition"
        :errors="errors.edition"
        field-type="select"
        label="Edition"
        placeholder="Choose an edition type..."
        :options="EDITION_CHOICES"
        :required="availableFields.edition.required"
        @update="onFieldUpdate"
        @input="onEditionInput"
      />
      <!-- ---------- SPECIAL FORM for limited edition --------- -->
      <template v-if="showEditionDetails || item.edition == 'L'">
        <h3>Limited Edition</h3>
        <BaseInputContainer
          v-model="item.edition_details.num_edition"
          field-type="integer"
          label="Num Edition"
          placeholder="Enter this edition number..."
          :errors="errors.edition_details?errors.edition_details.num_edition:''"
          :required="availableFields.edition_details.num_edition"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.edition_details.total_edition"
          field-type="integer"
          label="Total Edition"
          placeholder="Enter a total edition number..."
          :errors="errors.edition_details?errors.edition_details.total_edition:''"
          :required="availableFields.edition_details.total_edition"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.edition_details.num_ap"
          field-type="integer"
          label="Num AP"
          placeholder="Enter an Artist Proof number..."
          :errors="errors.edition_details?errors.edition_details.num_ap:''"
          :required="availableFields.edition_details.num_ap"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.edition_details.total_ap"
          field-type="integer"
          label="Total AP"
          placeholder="Enter a total AP number..."
          :errors="errors.edition_details?errors.edition_details.total_ap:''"
          :required="availableFields.edition_details.total_ap"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </template>
      <!-- SIGNED -->
      <BaseInputContainer
        v-model="item.signed"
        :errors="errors.signed"
        field-type="checkbox"
        label="signed"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- SIGNATURE -->
      <BaseInputContainer
        v-model="item.signature"
        :errors="errors.signature"
        field-type="string"
        label="Signature"
        placeholder="Type a signature..."
        :required="availableFields.signature.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- CERTIFICATE -->
      <BaseInputContainer
        v-model="item.certificate"
        :errors="errors.certificate"
        field-type="select"
        label="Certificate"
        placeholder="Choose a certificate origin..."
        :options="CERTIFICATE_CHOICES"
        :required="availableFields.certificate.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <SetEditor
        :item="item.media_set"
        :errors="errors.media_set"
        label="Work views"
        set-model="media_set"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      />
      <slot name="controls" />
    </section>

    <!-- ---------- REGISTRAR DETAILS --------- -->
    <section>
      <h2>Inventory</h2>
      <section>
        <!-- AVAILABILITY -->
        <BaseInputContainer
          v-model="item.availability"
          :errors="errors.availability"
          field-type="select"
          label="Availability"
          placeholder="Choose an availability status..."
          :options="AVAILABILITY_CHOICES"
          :required="availableFields.availability.required"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <!-- INVENTORY NUMBER -->
      <BaseInputContainer
        v-model="item.inventory"
        :errors="errors.inventory"
        field-type="string"
        label="Inventory"
        placeholder="Type an inventory..."
        :required="availableFields.inventory.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- SPECIAL CONDITIONS -->
      <BaseInputContainer
        v-model="item.special_conditions"
        :errors="errors.special_conditions"
        field-type="string"
        placeholder="Type special conditions..."
        label="Special conditions"
        :required="availableFields.special_conditions.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <slot name="controls" />
    </section>
    <section>
      <!-- ----- PHYSICAL STATE FORM ------ -->
      <h2>Physical State</h2>
      <PhysicalStateForm
        :item="item.physical_state"
        :errors="errors"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Price details</h2>
      <!-- PRICE -->
      <BaseInputContainer
        v-model="item.price"
        :errors="errors.price"
        field-type="string"
        label="Price"
        :required="availableFields.price.required"
        disabled
      />
      <!-- MINIMUM PRICE -->
      <BaseInputContainer
        v-model="item.price_min"
        :errors="errors.price_min"
        field-type="integer"
        label="Price min"
        placeholder="Enter a minimum price..."
        :required="availableFields.price_min.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- MAXIMUM PRICE -->
      <BaseInputContainer
        v-model="item.price_max"
        :errors="errors.price_max"
        field-type="integer"
        label="Price max"
        placeholder="Enter a maximum price..."
        :required="availableFields.price_max.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <!-- CURRENCY -->
      <BaseInputContainer
        v-model="item.currency"
        :errors="errors.currency"
        field-type="select"
        label="Currency"
        reverse-field="code"
        placeholder="Choose a currency..."
        :options="CURRENCY_CHOICES"
        :required="availableFields.currency.required"
        disabled
      />
      <BaseInputContainer
        v-model="item.pay_later"
        field-type="checkbox"
        label="Pay later"
        :errors="errors.pay_later"
        disabled
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Delivery details</h2>
      <!-- DELIVERY CHOICE -->
      <BaseInputContainer
        v-model="item.delivery"
        :errors="errors.delivery"
        field-type="multiselect"
        label="Delivery"
        placeholder="Choose delivery partners..."
        :options="DELIVERY_CHOICES"
        :required="availableFields.delivery.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.free_return"
        field-type="checkbox"
        label="Free return"
        :errors="errors.free_return"
        disabled
      />
      <slot name="controls" />
    </section>
    <section>
      <h2>Artwork location</h2>
      <AddressForm
        :item="item.address"
        :errors="errors.address"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
  </div>
</template>

<script>
// Mixin
import {modelForms} from '@/mixins/modelForms.js'
// Components
import AddressForm from '@/components/AddressForm'
import SetEditor from '@/components/SetEditor';
import PhysicalStateForm from './PhysicalStateForm'

export default {
  name: 'ArtworkForm',
  components: {
    AddressForm,
    PhysicalStateForm,
    SetEditor,
  },
  mixins: [modelForms],
  props: {},
  data: function (){
    return {
      showEditionDetails: false,
    }
  },
  computed: { },
  watch: {
    /**
     * The 'hintUpdateSeo' event is used to warn user about
     * modifications without updating 'metadata'.
     * Here we just _watch_ if artwork title is changed and 
     * warn if changed and artwork is published.
     */
    'item.title': function(){
      if (this.item.metadata.published){
        this.$emit('hintUpdateSeo', true)
      }
    }
  },
  methods: {
    /**
     * This method handles the modification of 'edition' details.
     * As only 'Limited Edition' should display the subform edition_details
     * we handle the `showEditionDetails`flag by derivating the 'input' event on
     * 'edition' choice input field.
     */
    onEditionInput: function (){
      // console.log('editioninput')
      /* This is a limited edition */
      if (this.item.edition == 'L'){
        /* It was not defined before, we walk on fresh snow */
        if(!this.item.edition_details){
          this.item.edition_details = {}
        }
        /**
         * let's tell the view something deep has changed 
         * and display the form
         */
        this.$nextTick(()=>{
          this.showEditionDetails = true
          this.$emit('dirty')
        })
      } else {
        /* this isn't a limited edition, let's hide the sub form */
        this.$nextTick(()=>{
          this.showEditionDetails = false
          this.$delete(this.item, 'edition_details')
          this.$emit('dirty')
        })
      }
      this.onFieldInput()
    }
  }
}

</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
section{
  width: 100%;
}
</style>
