import quotes from '@/assets/quotes.json'

const state = {
  // app state
  menuSmall: false,
  appLoading: false,
  appTitle: 'TAP-Admin',
  // modal
  modalShow: false,
  modalState: 'modal-hide',
  modalTheme: null,
  modalTitle: 'Default modal title',
  modalMessage: 'Default message',
  modalSuccessCallback: false,
  modalCancelCallback: false,
  // quote
  quoteOfTheDay: '',
  // device management
  isSmall: false,
  isMobile: false,
  viewportMinSizeBeforeSmall: 1200,
  viewportMinSizeBeforeMobile: 576,
}

const mutations = {
  setMenu: function(state, value){
    state.menuSmall = value
  },
  setModalShow(state, value){
    state.modalShow = value
  },
  setModalParams(state, params){
    state.modalTheme = params.theme
    state.modalTitle = params.title
    state.modalMessage = params.message
    state.modalSuccessCallback = params.successCallback
    state.modalCancelCallback = params.cancelCallback
  },
  setAppLoading: function(state, value){
    state.appLoading = value
  },
  setAppTitle: function(state, value){
    state.appTitle = value
  },
  setQuoteOfTheDay: function(state, value){
    state.quoteOfTheDay = value
  },
  setIsSmall: function(state, value){
    state.isSmall = value
  },
  setIsMobile: function(state, value){
    state.isMobile = value
  },
}

const actions = {
  setMenuSmall: function({commit}){
    commit('setMenu', true)
  },
  setMenuBig: function({commit}){
    commit('setMenu', false)
  },
  toggleMenuSize: function({state, commit}){
    commit('setMenu', !state.menuSmall)
  },
  openModal: function({commit}, params){
    commit('setModalShow', true)
    commit('setModalParams', params)
  },
  closeModal: function({commit}){
    commit('setModalShow', false)
  },
  setAppLoading: function ({commit}, value){
    commit('setAppLoading', value)
  },
  setAppTitle: function ({commit}, value){
    commit('setAppTitle', value)
    window.document.title = value
  },
  init: function({dispatch}) {
    dispatch('updateQuoteOfTheDay')
  },
  updateQuoteOfTheDay: async function ({commit}){
    const today = new Date()
    const idx = (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(today.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000
    commit('setQuoteOfTheDay', quotes[idx % quotes.length])
  }
}

const getters = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
