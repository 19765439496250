import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['NGO project', 'NGO projects'],
  modelName: 'project',
}

const mutations = {
  ...baseStore.mutations,
}

const actions = {
  ...baseStore.actions
}

const getters = {
  ...baseStore.getters,
  /* NOT AVAILABLE IN API 14/09/21 */
  // regionChoices: function (state){
  //   if (!state.fieldsReady) return []
  //   return state.availableFilterFields.region.choices
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
