import { render, staticRenderFns } from "./ArtworkFilters.vue?vue&type=template&id=df77687e&scoped=true&"
import script from "./ArtworkFilters.vue?vue&type=script&lang=js&"
export * from "./ArtworkFilters.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/filters.css?vue&type=style&index=0&id=df77687e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df77687e",
  null
  
)

export default component.exports