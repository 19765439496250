<template>
  <div class="filters-fields">
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.name"
        field-type="string"
        label="Name"
        placeholder="Name"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.country"
        field-type="select"
        :options="COUNTRY_CHOICES"
        reverse-field="code"
        label="Country"
        placeholder="Select country"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.featured"
        field-type="checkbox"
        label="Featured"
        placeholder="Featured"
        :validable="false"
        v-on="$listeners"
      />
    </div>
    <div class="row">
      <BaseInputContainer
        v-model="filterValues.tag"
        field-type="multiselect"
        placeholder="Select tags..."
        label="Tags"
        :data-source="$TapAdmin.tag"
        reverse-field="id"
        :validable="false"
        v-on="$listeners"
      />
      <BaseInputContainer
        v-model="filterValues.cause"
        field-type="multiselect"
        :options="CAUSE_CHOICES"
        reverse-field="id"
        placeholder="Select a cause..."
        :validable="false"
        display-id
        label="Causes"
        v-on="$listeners"
      />
    </div>
    <!-- TODO : DISABLED UNTIL WORKING -->
    <!-- <BaseInputContainer
      v-model="filterValues.city"
      field-type="select"
      :options="cityChoices"
      label="City"
      placeholder="Select city"
      :validable="false"
      v-on="$listeners"
    /> -->
    <!-- <BaseInputContainer
      v-model="filterValues.region"
      field-type="select"
      :options="regionChoices"
      label="Region"
      placeholder="Select region"
      :validable="false"
      v-on="$listeners"
    /> -->
  </div>
</template>

<script>
import { modelFilters } from '@/mixins/modelFilters.js'

export default {
  name: 'GalleryFilters',
  mixins: [modelFilters],
  data: function () {
    return {
      model: 'gallery',
    }
  },
}
</script>
<style src="../../assets/css/filters.css" scoped></style>
