import { mapGetters, mapState } from 'vuex'

export const modelForms = {
  props: {
    item: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    itemRaw: {
      type: Object,
      required: false,
      default: () => { return null }
    },
    modelName: {
      type: String,
      required: false,
      default: () => { return null }
    },
    errors: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },
  data: function(){
    return {
      dirty: false,
    }
  },
  computed: {
    ...mapState({
      state (state){
        return state[this.modelName]
      },
      availableFields (state){
        if (this.modelName) return state[this.modelName].availableFields
        return {}
      },
      endpoint (state){
        return state[this.modelName].endpoint
      },
    }),
    ...mapState('choices', [
      'AGE_FILTER_CHOICES',
      'AVAILABILITY_CHOICES',
      'CAUSE_CHOICES',
      'CERTIFICATE_CHOICES',
      'COUNTRY_CHOICES',
      'CURRENCY_CHOICES',
      'CV_TYPE_CHOICES',
      'DATE_FILTER_CHOICES',
      'DELIVERY_CHOICES',
      'DELIVERY_PACKING_CHOICES',
      'DELIVERY_OBJECT_TYPE_CHOICES',
      'EDITION_CHOICES',
      'FORMAT_CHOICES',
      'FRAGILITY_CHOICES',
      'GENDER_CHOICES',
      'GENRE_CHOICES',
      'MEDIUM_CHOICES',
      'PRICE_FILTER_CHOICES',
      'PROJECT_SOURCE_CHOICES',
      'SIZE_FILTER_CHOICES',
      'STATUS_CHOICES',
      'SUBSCRIPTION_CHOICES',
      'TAG_CATEGORY_CHOICES',
      'TAX_RECEIPT_CHOICES',
      'SHIPMENT_STATUS_CHOICES',
      'TRANSACTION_STATUS_CHOICES'
    ]),
  },
  methods: {
    onFieldUpdate: function(a,b,c){
      // console.log('Field update', a,b,c)
    },
    /**
     * Checking if item is really dirty
     */
    onFieldInput: function(a,b,c){
      // console.debug(`FORM: field input ${this.modelName}`, a)
      let realDirty = false
      const different = !this.checkEqual(this.item, this.itemRaw)
      // console.log('these are different', different)
      if (different || Array.isArray(different)){
        realDirty = true
      } else {
        realDirty = false
      }
      /* These events 'dirtyform' and 'formok' are only
         used by direct parent aka DETAILSVIEW.       */
      if (realDirty){
        this.dirty = true
        this.$emit('dirtyform')
      } else {
        this.dirty = false
        this.$emit('formok')
      }
    },
    /** 
     * Used by subforms to transmit dirty state
     * as it's easier than deep-parsing `item`
     * in `onFieldInput`
     */
    onDirtySubform: function (){
      this.$emit('dirtyform')
    },
    checkEqual: function(a,b){
      const aj = JSON.stringify(a)
      const bj = JSON.stringify(b)
      return aj == bj
    }

  },
  mounted: function(){
  }
}
