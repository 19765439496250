<template>
  <div class="dashboard">
    <!-- ------------ PAGE HEADER ------------ -->
    <header class="page-header">
      <blockquote class="motd">
        <p>&ldquo; {{ quoteOfTheDay.text }} &rdquo;</p>
        <cite>{{ quoteOfTheDay.author }}</cite>
      </blockquote>
    </header>
    <!-- TODO: que doit-il y avoir là dedans ? à réactiver le cas échéant -->
    <!-- <header class="subsection-header page-header">
      <h2 class="title-level-2">
        Recent activity
      </h2>
    </header> -->
    <!-- ------------ LOADER ------------ -->
    <BaseLoader v-if="isLoading" />
    <section class="contextual-results">
      <!-- TABS HEADER CONTAINER -->
      <header id="tabs-container">
        <template v-for="(tab, tabIdx) in availableTabs">
          <div
            :key="'tab' + tabIdx"
            :class="['tab', { active: activeTabName == tab.name[0] }]"
            @click="showTab(tab.name[0])"
          >
            <h4 class="title-level-4">
              {{ tab.counter == 0 ? `No` : `${tab.counter} ` }}
              {{ tab.name | pluralize(tab.counter) }}
            </h4>
            <div class="underline-bar" />
          </div>
        </template>
      </header>
      <!-- ------------ CONVERSATIONS ------------ -->
      <section id="list-container">
        <article
          v-if="!isLoading"
          v-show="activeTabName == 'conversation'"
        >
          <!-- THE TABLE -->
          <table>
            <tbody>
              <tr
                v-for="(item, itemIdx) in activeConversations.conversation"
                :key="'pconv' + itemIdx"
              >
                <!-- FIRST CELL -->
                <td class="main-cell">
                  <div>
                    <p class="item-title">
                      With: {{ item.last_comment.user.username }}
                    </p>
                    <p class="item-status">
                      Subject: {{ item.target.name }}
                    </p>
                    <p class="item-last-message">
                      {{ item.last_comment.message }}
                    </p>
                  </div>
                </td>
                <!-- LAST UPDATE CELL -->
                <td class="freshness-cell">
                  <p class="item-lastupdate">
                    Last message:
                    {{ item.last_comment.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
                  </p>
                </td>
                <!-- ACTIONS CELL -->
                <td class="default-actions">
                  <div class="actions-container">
                    <router-link :to="conversationLink(item)">
                      <BaseButton
                        :styles="['list-icon']"
                        title="go to item"
                        icon="edit"
                      />
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
        <!-- ------------ GALLERIES, ARTISTS, ARTWORKS ------------ -->
        <article
          v-if="!isLoading && activeTabName != 'conversation'"
        >
          <!-- THE TABLE -->
          <table>
            <tbody>
              <tr
                v-for="(conv, convIdx) in activeConversations[activeTabName]"
                :key="'pconv' + convIdx"
              >              
                <!-- FIRST CELL -->
                <td class="main-cell">
                  <h3 class="title-level-7">
                    {{ conv.target.model }}
                  </h3>
                  <div>
                    <p class="item-status">
                      {{ conv.target.name }}
                    </p>
                    <p class="item-last-message">
                      {{ conv.displayStatus }}
                    </p>
                  </div>
                </td>
                <!-- LAST UPDATE CELL -->
                <td class="freshness-cell">
                  <p class="item-from">
                    From: {{ conv.last_comment.user.username }}
                  </p>
                  <p>
                    {{ conv.last_comment.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
                  </p>
                </td>
                <!-- ACTIONS CELL -->
                <td class="default-actions">
                  <div class="actions-container">
                    <router-link :to="conversationLink(conv)">
                      <BaseButton
                        :styles="['list-icon']"
                        title="go to item"
                        icon="edit"
                      />
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
// Store
import { mapState } from 'vuex'
// Components
const ACTIVE_CONV_MODELS = ['artwork', 'gallery', 'artist']

export default {
  name: 'Dashboard',
  components: {},
  data: function () {
    return {
      isLoading: true /* loading status of the dashboard results */,
      activeTabName: 'conversation',
      conversationsRaw: {}
    }
  },
  computed: {
    ...mapState({
      quoteOfTheDay(state) {
        return state.ui.quoteOfTheDay
      },
      // userName(state) {
      //   const currentUser = state.user.currentUser
      //   return `${currentUser.first_name} ${currentUser.last_name}`.trim() 
      //     || currentUser.username
      // },
    }),
    availableTabs: function () {
      if (!this.activeConversations) return []
      const tabs = [
        {
          name: ['conversation', 'conversations'],
          counter: this.activeConversations.conversation.length
        },
        {
          name: ['gallery', 'galleries'],
          counter: this.activeConversations.gallery.length
        },
        {
          name: ['artist', 'artists'],
          counter: this.activeConversations.artist.length
        },
        {
          name: ['artwork', 'artworks'],
          counter: this.activeConversations.artwork.length
        },
      ]
      return tabs
    },
    activeConversations: function () {
      if (Object.keys(this.conversationsRaw).length == 0) return null
      let convs = {}
      Object.keys(this.conversationsRaw).forEach((ck) => {
        // console.log(ck, this.conversationsRaw[ck])
        convs[ck] = this.conversationsRaw[ck].map((conv)=>{
          return {
            ...conv,
             displayStatus: this.$store.state.choices.STATUS_CHOICES
              .find((c) => c.value == conv.status)
              .display_name
          }
        })
      })
      return convs
    }
  },
  mounted: async function () {
    await this.updateList()
  },
  methods: {
    /**
     * Fetching all the items displayed on page before display.
     */
    updateList: async function () {
      try {
        this.isLoading = true
        await this.fetchConversations()
      } catch (error) {
        console.warn(error)
      } finally {
        this.isLoading = false
      }
    },
    // validateGallery: function (gallery) {
    //   const metadata = Object.assign({}, gallery.metadata);
    //   metadata.status = 'O'; /* status OK */
    //   this.$TapAdmin.gallery.update(gallery.id, { metadata: metadata })
    //     .then((response) => {
    //       this.updateList();
    //     });
    // },
    /**
     * ------------ conversation methods
     */
    fetchConversations: async function () {
      return this.$TapAdmin.conversations.get()
        .then((response)=>{
          console.debug('DASHBOARD: conversations', response.data)
          const withMessage = response.data.results.filter((conv)=> conv.last_comment != null)
          let convs = {}
          ACTIVE_CONV_MODELS.forEach((model)=>{
            convs[model] = withMessage.filter((conv) => conv.target.model == model)
          })
          convs.conversation = [...withMessage]
          this.conversationsRaw = {...convs}
        })
    },
    /**
     * ------------ Conversation management
     * @param {Object} item
     * @returns {Object} - The target route of the link.
     */
    conversationLink: function (item) {
      const linkTo = {
        name: `${item.target.model}-view`,
        params: { id: item.target.id }
      }
      return linkTo
    },
    /**
     * ------------ TAB management
     * @param {String} tabName
     */
    showTab: function (tabName) {
      this.activeTabName = tabName
    },
  },
}
</script>

<style scoped>
.page-header {
  border: var(--default-admin-border);
  padding: 20px;
  margin-bottom: 15px;
}
.page-header h2 {
  font-family: 'Rubik SemiBold';
  font-size: var(--h3);
  text-transform: uppercase;
  margin-bottom: 20px;
}
.motd {
  font-family: 'Filson Pro Bold Italic';
  font-size: var(--paragraph);
  text-align: center;
}
.motd cite {
  font-family: 'Filson Pro Bold';
  font-style: normal;
}
.subsection-header {
  font-family: 'Filson Pro Bold';
  font-size: var(--h5);
  letter-spacing: var(--h5-ls);
  display: flex;
  align-items: baseline;
}
.subsection-header .title-level-2 {
  margin-bottom: 0px;
}
article {
  border: var(--default-admin-border);
  padding: 15px 20px;
  margin-bottom: 20px;
}
table {
  width: 100%;
  border-spacing: 0px 20px;
  font-family: 'Rubik Regular';
}
table tr {
  outline: var(--default-admin-border);
}
td {
  padding: 20px 26px;
  height: 90px;
  box-sizing: border-box;
}
.main-cell p {
  margin: 2px 0px;
}
.item-status {
  font-size: var(--h5);
}
.item-last-message {
  font-style: italic;
}
td.default-actions {
  border-left: var(--default-admin-border);
  width: 200px;
  box-sizing: border-box;
}
.default-actions .actions-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.counter {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.contextual-results {
  background-color: var(--white);
}

#list-container {
  background-color: var(--white);
}

#tabs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.tab {
  margin: auto 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.tab h4 {
  line-height: calc(2 * var(--h4-lh));
}

.tab.active {
  color: var(--primary-color);
}

.tab.active .underline-bar {
  height: 5px;
  width: 80px;
  background-color: var(--primary-color);
}
.tab-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
