<template>
  <div>
    <section>
      <BaseInputContainer
        v-model="item.legal_name"
        field-type="string"
        label="Legal name"
        :errors="errors.legal_name"
        required
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.legal_id"
        field-type="string"
        label="Legal id"
        :errors="errors.legal_id"
        required
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.email"
        placeholder=""
        :errors="errors.email"
        field-type="string"
        label="Email"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.username"
        field-type="string"
        label="Username"
        placeholder=""
        :errors="errors.username"
        disabled
      />
      <BaseInputContainer
        v-model="item.date_joined"
        field-type="date"
        label="Date joined"
        placeholder=""
        :errors="errors.date_joined"
        disabled
      />
    </section>
    <section>
      <!-- disabled -->
      <BaseInputContainer
        v-model="stripeId"
        field-type="string"
        label="Stripe Connect ID"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="stripeOk"
        field-type="checkbox"
        label="Stripe Connect completed"
        placeholder=""
        disabled
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.subscription"
        field-type="select"
        label="Subscription"
        placeholder="Pick a choice"
        :options="SUBSCRIPTION_CHOICES"
        required
        :errors="errors.subscription"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <!-- Comment for now as IBAN cannot be retrieved from stripe -->
    <!-- See https://support.outofpluto.com/projects/theartpledge-platform/wiki/Stripe_Connect -->
    <!-- <section> -->
    <!--   <SetEditor -->
    <!--     v-if="item.payout_account" -->
    <!--     :item="item.payout_account.bank_account_set" -->
    <!--     :errors="errors.payout_account" -->
    <!--     label="Bank accounts" -->
    <!--     set-model="bank_account_set" -->
    <!--     v-on="$listeners" -->
    <!--     @dirtysubform="onDirtySubform" -->
    <!--   /> -->
    <!-- </section> -->
  </div>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'

export default {
  name: 'LegalInformation',
  components: {},
  mixins: [modelForms],
  data: function () {
    return {}
  },
  computed: {
    stripeId: function() {
      if (this.item.payout_account) {
        return this.item.payout_account.stripe_id
      }else{
        return ''
      }
    },
    stripeOk: function() {
      if (this.item.payout_account) {
        return this.item.payout_account.stripe_ok
      }else{
        return false
      }
    },
  },
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
div {
  width: 100%;
}
.base-input-container {
  width: 30%;
}
</style>
